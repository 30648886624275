import React, { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Table, Tabs, Input, Empty, Divider, Alert, Popover } from 'antd';
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import Globals from '../../config/globals';
import SkeletonTable from '../../helpers/SkeletonTable';
import { useForm } from "react-hook-form";
import { orgInfoLoading } from '../../services/OrganizationsService';
import { activeUserInfo, activeUserPermissions } from '../../services/UsersService';
import { activeJobTab, activeUserJobsState, jobTableParams } from '../../services/JobsService';
import { AddJob } from './AddJob';
import { MatchPane } from '../Placements/MatchPane';
import JobPlacementWorkflow from './JobPlacementWorkflow';
import { useJobs } from '../../swr/jobs';

export function JobsHome(props) {

    const navigate = useNavigate();
    const { register, watch } = useForm();

    const isDesktop = useMediaQuery({ minWidth: Globals.xlScreen });

    const [loading, setLoading] = useState(false);

    const uInfo = useRecoilValue(activeUserInfo);
    const uPermissions = useRecoilValue(activeUserPermissions);

    const [jobsToFilterAgainst, setJobsToFilterAgainst] = useState([]);

    const [activeTab, setActiveTab] = useRecoilState(activeJobTab);

    const [activeUserJobs, setActiveUserJobs] = useRecoilState(activeUserJobsState);

    const [jobLists, setJobLists] = useState({
        jobList: [],
        allUserJobs: [],
        filterUserJobs: [],
        allJobs: [],
        loaded: false
    });

    const [workflowPane, setWorkflowPane] = useState({
        show: false,
        job: null
    });

    const [showAddJob, setShowAddJob] = useState(false);

    const [status, setStatus] = useState('Active');

    const [tableParams, setTableParams] = useRecoilState(jobTableParams);

    const [sortStuff, setSortStuff] = useState({
        sortBy: "",
        sortDirection: null
    });

    const [searchValue, setSearchValue] = useState('');
    const [jobOwnership, setJobOwnership] = useState('user');
    
    const [showArchived, setShowArchived] = useState(false);

    const { data, isLoading, error } = useJobs(
        uInfo.organizations_ID, 
        uInfo.users_ID, 
        status, 
        tableParams.pagination.pageSize, 
        tableParams.pagination.current - 1, 
        searchValue,
        sortStuff.sortBy,
        sortStuff.sortDirection,
        showArchived,
        jobOwnership
    );


    useEffect(() => {
        var alive = true;

        combineLatest(
            orgInfoLoading
        ).pipe(takeWhile(() => alive)).subscribe(([orgInfoLoading]) => {
            setLoading(orgInfoLoading);
        });

        return () => { alive = false; }
    }, []);

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if(name === 'jobOwnership') {
                setJobOwnership(value.jobOwnership);
            }
        });

        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if (!data) return;
        setActiveUserJobs(data);
    }, [data, isLoading]);

    useEffect(() => {
        if (!loading && activeUserJobs) {
            let jobsToSet = activeUserJobs.userJobs ? [...activeUserJobs.userJobs] : [];

            if(activeTab === 'companyJobs') { 
                jobsToSet = activeUserJobs.companyJobs ? [...activeUserJobs.companyJobs] : [];
            }

            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: jobsToSet.length > 0 ? jobsToSet[0].totalCount : 0
                }
            });

            setJobLists({
                jobList: jobsToSet,
                allUserJobs: activeUserJobs.userJobs ? [...activeUserJobs.userJobs] : [],
                filterUserJobs: jobsToSet,
                allJobs: activeUserJobs.companyJobs ? [...activeUserJobs.companyJobs] : [],
                loaded: true
            });

            setJobsToFilterAgainst(jobsToSet);
        }

        setLoading(false);
    }, [activeUserJobs, activeTab, loading]);

    useEffect(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }, [showArchived]);

    function handleTabChange(activeKey) {
        setActiveTab(activeKey);
    }


    function navigateToJobDashboard(row) {
        navigate(`/job-dashboard/${row.jobs_ID}`,
            {
                replace: true
            });
    }

    function showWorkflowPane(job) {
        setWorkflowPane({
            show: true,
            job
        });
    }

    function matchFormatter(cell, row) {
        return (
            <button id="matchButton" className="ant-btn ant-btn-match btn-sm" onClick={() => showWorkflowPane(row)}> Match Candidates</ button>
        );
    }

    function getSortDirection(column) {
        var direction = null;
        var sortBy = "";

        if(sortStuff.sortDirection === null || sortStuff.sortBy !== column.key) {
            sortBy = column.key;
            direction = 'asc';
        } else if (sortStuff.sortDirection === 'asc') {
            sortBy = column.key;
            direction = 'desc';
        }

        return { sortBy, direction };
    }

    function iconFormatter(cell, row) {
        return (
            <>
                {row.confidential &&
                    <Popover overlayInnerStyle={{ width: '200px' }} placement="right" content={<p>You have configured this job to be confidential, meaning it can only be seen by you.</p>}>
                        <i className="fa-solid fa-key"></i>
                    </Popover>
                }
                {row.users_ID !== uInfo.users_ID &&
                    <Popover overlayInnerStyle={{ width: '200px' }} placement="right" content={<p>This job is owned by {row.recruiter}</p>}>
                        <i className="job-text fa-solid fa-people-roof"></i>
                    </Popover>
                }
            </>
        );
    }

    var jobColumns = [
        {
            title: '',
            dataIndex: 'confidential',
            key: 'confidential',
            render: iconFormatter,
            responsive: ['md'],
            width: 50
        },
        {
            title: 'Client',
            dataIndex: 'clientName',
            key: 'clientName',
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'clientName' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'clientName' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => { 
                        var { direction, sortBy } = getSortDirection(column);
                        setSortStuff({sortBy, sortDirection: direction }) 
                    }
                };
            },
            responsive: ['xl']
        },
        {
            title: 'Job Name',
            dataIndex: 'jobTitle',
            key: 'jobTitle',
            ellipsis: true,
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'jobTitle' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'jobTitle' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => { 
                        var { direction, sortBy } = getSortDirection(column);
                        setSortStuff({sortBy, sortDirection: direction }) 
                    }
                };
            },
        },
        {
            title: 'Specialty',
            dataIndex: 'parentSpecialty',
            key: 'parentSpecialty',
            ellipsis: true,
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'parentSpecialty' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'parentSpecialty' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => { 
                        var { direction, sortBy } = getSortDirection(column);
                        setSortStuff({sortBy, sortDirection: direction }) 
                    }
                };
            },
            responsive: ['xl']
        },
        {
            title: 'Sub-Specialty',
            dataIndex: 'subSpecialty',
            key: 'subSpecialty',
            ellipsis: true,
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'subSpecialty' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'subSpecialty' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => { 
                        var { direction, sortBy } = getSortDirection(column);
                        setSortStuff({sortBy, sortDirection: direction }) 
                    }
                };
            },
            responsive: ['xl']
        },
        {
            dataIndex: 'city',
            title: 'City',
            key: 'city',
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'city' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'city' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => { 
                        var { direction, sortBy } = getSortDirection(column);
                        setSortStuff({sortBy, sortDirection: direction }) 
                    }
                };
            },
            responsive: ['xl']
        },
        {
            dataIndex: 'state',
            title: 'State',
            key: 'state',
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'state' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'state' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => { 
                        var { direction, sortBy } = getSortDirection(column);
                        setSortStuff({sortBy, sortDirection: direction }) 
                    }
                };
            },
            responsive: ['xl']
        },
        {
            title: 'Recruiter',
            dataIndex: 'recruiter',
            key: 'recruiter',
            responsive: ['xl'],
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'recruiter' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'recruiter' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => { 
                        var { direction, sortBy } = getSortDirection(column); 
                        setSortStuff({sortBy, sortDirection: direction }) 
                    }
                };
            },
        },
        {
            title: 'Match',
            render: matchFormatter
        },
    ];

    function handleSearch(value) {
        setSearchValue(value);
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination: {
                ...tableParams.pagination,
                current: pagination.current,
                pageSize: pagination.pageSize
            },
            filters,
            ...sorter,
        });
    };

    const columns = jobColumns.filter(c => !c.hide);

    const dropdownRender = (record) => {
        return (
            <div className="ms-4">
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <label>Confidential:</label>
                        &nbsp;
                        {record.confidential ? "Yes" : "No"}
                    </div>
                    <div className="col-lg-6 col-12">
                        <label>Client:</label>
                        &nbsp;
                        {record.clientName}
                    </div>
                    <div className="col-lg-6 col-12">
                        <label>Specialty:</label>
                        &nbsp;
                        {record.parentSpecialty}
                    </div>
                    <div className="col-lg-6 col-12">
                        <label>Sub-Specialty:</label>
                        &nbsp;
                        {record.subSpecialty}
                    </div>
                    <div className="col-lg-6 col-12">
                        <label>City:</label>
                        &nbsp;
                        {record.city}
                    </div>
                    <div className="col-lg-6 col-12">
                        <label>State:</label>
                        &nbsp;
                        {record.state}
                    </div>
                    <div className="col-lg-6 col-12">
                        <label>Recruiter:</label>
                        &nbsp;
                        {record.recruiter}
                    </div>
                </div>
            </div>
        );
    }

    var tabs = [
        {
            label: <div className="job-text">Job Workflow Summary</div>,
            key: 'jobWorkflowSummary',
            children: (
                <JobPlacementWorkflow />
            )
        },
        {
            label: <div className="job-text">Jobs</div>,
            key: 'myJobs',
            children: (
                <>
                    {(loading) ?
                        <SkeletonTable columns={columns} rowCount={5} />
                        :
                        <>
                            <div className="row">
                                {uPermissions?.users_ID && uPermissions?.readJobs === 'company' &&
                                    <div className="col-xl-2 col-lg-3 col-12">
                                        <label>Filter by Job Ownership</label>
                                        <select className="ant-input" {...register('jobOwnership')}>
                                            <option value="user">My Jobs</option>
                                            <option value="org">Organizational Jobs</option>
                                        </select>
                                    </div>
                                }

                                <div className="form-group col-xl-2 col-lg-3 col-6">
                                    <label className="d-block">Show Archived Jobs</label>
                                    <input type="checkbox" className="custom-checkbox" checked={showArchived} onChange={() => setShowArchived(!showArchived)} />
                                </div>

                                {tableParams.pagination.total >= 0 &&
                                    <div className="form-group col-lg-2 col-6 mx-3 align-items-center">
                                        Total:&nbsp;<strong>{tableParams.pagination.total} Job(s)</strong>
                                    </div>
                                }
                            </div>
                            <Divider></Divider>
                            <Input.Search
                                placeholder="Search for keywords..."
                                onSearch={handleSearch}
                                onChange={(e) => { handleSearch(e.target.value) }}
                            />
                            <Divider></Divider>
                            {jobLists.filterUserJobs?.length > 0 ?
                                <>
                                    <Table
                                        rowKey={(record) => record.jobs_ID}
                                        dataSource={jobLists.filterUserJobs}
                                        columns={columns}
                                        size='small'
                                        onRow={(record, rowIndex) => {
                                            return {
                                                onClick: event => { (event.target.id !== 'matchButton' && event.target.id !== 'myCheckbox') ? navigateToJobDashboard(record) : console.log("Selected") }, // click row
                                                onDoubleClick: event => { }, // double click row
                                                onContextMenu: event => { }, // right button click row
                                                onMouseEnter: event => { }, // mouse enter row
                                                onMouseLeave: event => { }, // mouse leave row
                                            };
                                        }}
                                        expandable={!isDesktop && {
                                            expandedRowRender: (record) => dropdownRender(record),
                                            rowExpandable: (record) => record.jobs_ID !== 'Not Expandable',
                                        }}
                                        onChange={handleTableChange}
                                        pagination={tableParams.pagination}
                                    />
                                </>
                                :
                                <Empty description={<p>You currently have no jobs - to begin adding a job, click the <i className="far fa-plus-square" /> icon on the top banner, and select 'Add a Job'.</p>} />
                            }
                        </>
                    }
                </>
            ),
        }
        // ,
        // {
        //     label: <div className="job-text">Organizational Jobs</div>,
        //     key: 'companyJobs',
        //     children: (
        //         <>
        //             {(loading) ?
        //                 <SkeletonTable columns={columns} rowCount={5} />
        //                 :
        //                 <>
        //                     <div className="d-flex">
        //                         <div className="form-group mx-1 d-flex">
        //                             <input type="checkbox" className="custom-checkbox" checked={showArchived} onChange={() => setShowArchived(!showArchived)} />
        //                             <label className="ms-1 align-self-center">Show Archived Jobs</label>
        //                         </div>

        //                         {tableParams.pagination.total >= 0 &&
        //                         <div className="form-group mx-3 ps-4 d-flex align-items-center">
        //                             Total:&nbsp;<strong>{tableParams.pagination.total} Job(s)</strong>
        //                         </div>
        //                     }
        //                     </div>
        //                     <Divider></Divider>
        //                     <Input.Search
        //                         placeholder="Search for keywords..."
        //                         onSearch={handleSearch}
        //                         onChange={(e) => { handleSearch(e.target.value) }}
        //                     />
        //                     <Divider></Divider>
        //                     {jobLists.filterUserJobs?.length > 0 ?
        //                         <>


        //                             <Table
        //                                 rowKey={(record) => record.jobs_ID}
        //                                 dataSource={jobLists.filterUserJobs}
        //                                 columns={columns.filter(c => c.key !== 'confidential')}
        //                                 size='small'
        //                                 onRow={(record, rowIndex) => {
        //                                     return {
        //                                         onClick: event => { (event.target.id !== 'matchButton' && event.target.id !== 'myCheckbox') ? navigateToJobDashboard(record) : console.log("Selected") }, // click row
        //                                         onDoubleClick: event => { }, // double click row
        //                                         onContextMenu: event => { }, // right button click row
        //                                         onMouseEnter: event => { }, // mouse enter row
        //                                         onMouseLeave: event => { }, // mouse leave row
        //                                     };
        //                                 }}
        //                                 expandable={!isDesktop && {
        //                                     expandedRowRender: (record) => dropdownRender(record),
        //                                     rowExpandable: (record) => record.jobs_ID !== 'Not Expandable',
        //                                 }}
        //                                 onChange={handleTableChange}
        //                                 pagination={tableParams.pagination}
        //                             />
        //                         </>
        //                         :
        //                         <Empty description={<p>You currently have no jobs - to begin adding a job, click the <i className="far fa-plus-square" /> icon on the top banner, and select 'Add a Job'.</p>} />
        //                     }
        //                 </>
        //             }
        //         </>
        //     ),
        // }
    ];

    return (
        <>
            {(uPermissions.users_ID && uPermissions.readJobs === 'none') ?
                <Empty description={<p>You do you have the appropriate access to view jobs. Please contact your system administrator if you believe this is a mistake.</p>} />
                :
                <>
                    {uPermissions?.users_ID && uPermissions?.createJobs &&
                        <div className="d-flex justify-content-between">
                            <Alert className="float-start mb-3" message="Full job functionality is still in progress. More robust features are on the way." type="info" showIcon closable />
                            <button className="ant-btn ant-btn-primary float-end" onClick={() => setShowAddJob(true)}>
                                <i className="far fa-plus-square"></i>
                                &nbsp;
                                Add Job
                            </button>
                        </div>
                    }
                    <div className="card-container">
                        <Tabs activeKey={activeTab} type="card" items={tabs} onChange={handleTabChange} />
                    </div>

                    <AddJob show={showAddJob} from='jobs-dashboard' hidePane={() => setShowAddJob(false)} />
                    <MatchPane show={workflowPane.show} hidePane={() => setWorkflowPane({ show: false, job: null })} entity={workflowPane.job} from='jobs' placementStatus="Active" />
                </>
            }
        </>
    );
}