import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Card, Drawer, Select, Alert, Input, Modal, Spin, Tooltip } from 'antd';
import statesList from '../../helpers/StatesList';
import countryStatesList from '../../helpers/CountriesStates';
import Globals from '../../config/globals';
import axios from 'axios';
import { useForm, Controller, useFormState } from 'react-hook-form';
import { jobTypes, visaTypes, parentSpecialties, subSpecialties, orgTags, getOrganizationInfo, typesOfPractice, activeOrgTagsState, activeParentSpecialtiesState, activeSubSpecialtiesState, activeOrgInfoState, updateOrganization } from '../../services/OrganizationsService';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { toast, confirm } from '@rickylandino/react-messages';
import { PatternFormat, NumericFormat } from 'react-number-format';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import MediaQuery from 'react-responsive';
import { addCandidate, isPhoneNumberUnique, userCandidates } from '../../services/CandidateService';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { insertTag } from '../../services/TagsService';

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import '../../css/editor.css';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { insertSpecialty } from '../../services/SpecialtiesService';
import ImportCandidateResume from './ImportCandidateResume';
import { sampleCandidate } from './samplecandidate';
import { addCandidateAttachment } from '../../services/CandidateAttachmentsService';
import { activeUserInfo } from '../../services/UsersService';

export function AddCandidate(props) {
    const navigate = useNavigate();

    const { register, getValues, setValue, control, watch, formState, reset } = useForm();

    const setActiveParentSpecialties = useSetRecoilState(activeParentSpecialtiesState);
    const [activeSubSpecialties, setActiveSubSpecialties] = useRecoilState(activeSubSpecialtiesState);
    const [orgInfo, setOrgInfo] = useRecoilState(activeOrgInfoState);
    const userInfo = useRecoilValue(activeUserInfo);
    //const setActiveSubSpecialties = useSetRecoilState(activeSubSpecialtiesState);

    const [resume, setResume] = useState(null);
    const [fileText, setFileText] = useState(null);
    const [seeThroughActive, setSeeThroughActive] = useState(false);
    const [showParseBtn, setShowParseBtn] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState('US');
    const [resumeParsed, setResumeParsed] = useState(false);
    const [updating, setUpdating] = useState(false);

    const parsingModal = useRef(null);

    const formFieldsDefault = {
        firstName: '',
        middleName: null,
        lastName: '',
        specialty: '',
        subSpecialty: '',
        specialRequirements: null,
        providerType: null,
        whyLeaving: null,
        whenAvailable: null,
        duration: '',
        confidential: false,
        country: 'US',
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        yearsOfExperience: '',
        languages: null,
        visaTypes_ID: null,
        boardCertified: '',
        willingToRelocate: 'no',
        currentCompensationType: null,
        currentCompensation: null,
        desiredCompensation: null,
        desiredCompensationType: null,
        desiredPosition: null,
        compensationComments: null,
        facebook: '',
        linkedIn: '',
        twitter: '',
        url: '',
        problems: null,
        problemsText: null,
        malpracticeSuits: null,
        malpracticeSuitsText: null,
        affectPlacement: null,
        affectPlacementText: null,
        inHouseID: null,
        medicalSchoolAttended: null,
        medicalSchoolGraduationDate: null,
        typesOfPractice: null
    }

    const phoneNumbersDefault = [{
        phoneNumber: '',
        type: 'work',
        preferred: false,
        receiveSMS: false,
        isDirty: false,
        show: true
    }];

    const emailDefault = {
        emailAddress: '',
        emailType: 'primary',
        preferred: false,
        doNotEmail: false,
        isDirty: false,
        show: true
    };

    const certificationDefault = {
        name: '',
        description: ''
    }

    const educationDefault = {
        schoolName: "",
        schoolDate: null,
        degree: "",
        isCurrent: false,
        areaOfStudy: ""
    }

    const workHistoryDefault = {
        title: "",
        company: "",
        startDate: null,
        endDate: null,
        workType: "",
        description: "",
        isCurrent: false
    }

    const referenceDefault = {
        firstName: "",
        lastName: "",
        affix: "",
        phone: ""
    };

    const facebookPrefix = "https://facebook.com/";
    const linkedInPrefix = "https://www.linkedin.com/in/";
    const twitterPrefix = "https://www.twitter.com/";
    const urlPrefix = "https://";

    const [show, setShow] = useState(false);
    const [showAddSubSpecialty, setShowAddSubSpecialty] = useState(false);

    const [listData, setListData] = useState({
        jobTypes: [],
        parentSpecialties: [],
        subSpecialties: [],
        visaTypes: [],
        tagsList: [],
        unModifiedTags: [],
        loaded: false
    });

    const [specialtyInfo, setSpecialtyInfo] = useState({
        subSpecialties: [],
        selectedAdditionalSpecialties: []
    });

    const [candidates, setCandidates] = useState([]);


    const [modifiedStatesList, setModifiedStatesList] = useState([]);

    const [preferredStates, setPreferredStates] = useState([]);

    const [selectedJobTypes, setSelectedJobTypes] = useState([]);
    const [showDuration, setShowDuration] = useState(false);

    const [selectedTypesOfPractice, setSelectedTypesOfPractice] = useState([]);

    const [phoneNumbers, setPhoneNumbers] = useState(phoneNumbersDefault);
    const [phoneNumberNotUnique, setPhoneNumberNotUnique] = useState(false);

    const [emailAddresses, setEmailAddresses] = useState([emailDefault]);

    const [selectedCertifications, setSelectedCertifications] = useState([certificationDefault]);
    const [selectedLicensureRecords, setSelectedLicensureRecords] = useState([]);
    const [selectedRelocationStates, setSelectedRelocationStates] = useState([]);

    const [educationHistory, setEducationHistory] = useState([educationDefault]);

    const [workHistory, setWorkHistory] = useState([workHistoryDefault]);

    const [references, setReferences] = useState([referenceDefault]);

    const [selectedTags, setSelectedTags] = useState([]);

    const [willingToRelocate, setWillingToRelocate] = useState(false);
    const [showProblems, setShowProblems] = useState(false);
    const [showMalpractice, setShowMalpractice] = useState(false);
    const [showAffectPlacement, setShowAffectPlacment] = useState(false);

    const [duplicateInfo, setDuplicateInfo] = useState({
        duplicates: [],
        chosenDuplicate: {},
        isChosenDuplicate: false
    });

    const [activeOrgTags, setActiveOrgTags] = useRecoilState(activeOrgTagsState);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    var isDirty = useRef(false);

    useEffect(() => {
        var alive = true;

        setValue('formFields', formFieldsDefault);

        combineLatest(
            visaTypes,
            jobTypes,
            orgTags,
            parentSpecialties,
            subSpecialties,
            userCandidates,
            typesOfPractice
        ).pipe(takeWhile(() => alive)).subscribe(([visaTypes, jobTypes, orgTags, parentSpecialties, subSpecialties, userCandidates, typesOfPractice]) => {
            if (userCandidates?.userCandidates) {
                let theseCandidates = userCandidates.userCandidates.map(uc => uc.candidates);
                setCandidates(theseCandidates);
            }

            setListData({
                jobTypes: jobTypes.map(jt => ({ label: jt.jobType, value: jt.jobTypes_ID })),
                parentSpecialties,
                subSpecialties,
                visaTypes,
                orgTags: orgTags.map(ot => ({ label: ot.tag, value: ot.tags_ID })),
                unModifiedTags: orgTags,
                loaded: true,
                typesOfPractice: typesOfPractice.map(jt => ({ label: jt.typeOfPractice, value: jt.typesOfPractice_ID }))
            });
        });

        modifyStates();

        return () => { alive = false; }
    }, []);

    useEffect(() => {
        if (listData.loaded) {
            const subscription = watch((value, { name, type }) => {
                if (type === 'change') {
                    isDirty.current = true;
                }

                if (name === 'formFields.specialty') {
                    setValue('formFields.subSpecialty', null);
                    getSubSpecialtiesByParent(getValues().formFields.specialty);
                }

                if (name === 'formFields.country') {
                    var states = statesList.filter(obj => {
                        return obj.country_code === value.formFields.country
                    });

                    modifyStates(states);

                    setSelectedCountry(value.formFields.country);
                }

                if (name === 'formFields.willingToRelocate') {
                    if (value.formFields.willingToRelocate === 'yes') {
                        setWillingToRelocate(true)
                    } else {
                        setWillingToRelocate(false);
                        setSelectedRelocationStates([]);
                    }
                }

                if (name === 'formFields.problems') {
                    if (value.formFields.problems === 'yes') {
                        setShowProblems(true)
                    } else {
                        setShowProblems(false);
                    }
                }

                if (name === 'formFields.malpracticeSuits') {
                    if (value.formFields.malpracticeSuits === 'yes') {
                        setShowMalpractice(true)
                    } else {
                        setShowMalpractice(false);
                    }
                }

                if (name === 'formFields.affectPlacement') {
                    if (value.formFields.affectPlacement === 'yes') {
                        setShowAffectPlacment(true)
                    } else {
                        setShowAffectPlacment(false);
                    }
                }

                if (name === 'formFields.firstName' || name === 'formFields.lastName') {
                    const duplicates = candidates.filter(candidate => {
                        return (
                            (((name === 'formFields.firstName' && candidate['lastName']?.toLowerCase() === value.formFields.lastName?.toLowerCase()))) && candidate['firstName']?.toLowerCase().startsWith(value.formFields.firstName.toLowerCase())
                            ||
                            (((name === 'formFields.lastName' && value.formFields.firstName === '') || (name === 'formFields.lastName' && candidate['firstName']?.toLowerCase() === value.formFields.firstName?.toLowerCase()))) && candidate['lastName']?.toLowerCase().startsWith(value.formFields.lastName?.toLowerCase())
                        );
                    }
                    );

                    setDuplicateInfo({
                        duplicates,
                        chosenDuplicate: duplicates.length === candidates.length ? null : duplicates[0],
                        isChosenDuplicate: (duplicates.length !== candidates.length && duplicates.length > 0) ? true : false
                    });
                }

            });


            return () => subscription.unsubscribe();
        }

    }, [watch, listData]);

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    useEffect(() => {
        if(selectedCountry) {
            modifyStates();
        }
        
    }, [selectedCountry]);

    function modifyStates(states = null) {
        let newList = states ? states : statesList.filter(obj => { return obj.country_code === selectedCountry });

        newList = newList.map(item => ({
            label: item.name,
            value: item.state_code
        }));

        setModifiedStatesList(newList);
    }

    function handleClose() {
        if (isDirty.current) {
            confirm({
                title: "You have unsaved changes.",
                content: "Are you sure you would like to proceed?",
                buttons: ["Yes", "No"],
                theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
            },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        performClose();

                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        return 0;
                    }
                }
            );
        } else {
            performClose();
        }
    }

    function performClose() {
        setShow(false);
        props.hidePane();
        isDirty.current = false;
        Globals.isDirtyPage = false;

        setResume(null);
        setPhoneNumberNotUnique(false);

        //setValue('formFields', formFieldsDefault);
        reset({ formFields: formFieldsDefault });
        setSpecialtyInfo({
            ...specialtyInfo,
            subSpecialties: []
        });

        setPreferredStates([]);
        setSelectedJobTypes([]);
        setPhoneNumbers(phoneNumbersDefault);
        setEmailAddresses([emailDefault]);
        setSelectedLicensureRecords([]);
        setSelectedRelocationStates([]);
        setSelectedCertifications([certificationDefault]);

        setEducationHistory([educationDefault]);
        setWorkHistory([workHistoryDefault]);
        setReferences([referenceDefault]);
        setSelectedTags([]);
        setDuplicateInfo({
            duplicates: [],
            chosenDuplicate: {},
            isChosenDuplicate: false
        });

        setShowProblems(false);
        setShowMalpractice(false);
        setShowAffectPlacment(false);
        setSelectedTypesOfPractice([]);
    }

    function handleSubmit() {
        let candidateData = { ...getValues().formFields };
        candidateData.specialty = candidateData.specialty ? parseInt(candidateData.specialty) : 0;
        candidateData.subSpecialty = candidateData.subSpecialty ? parseInt(candidateData.subSpecialty) : 0;
        candidateData.willingToRelocate = candidateData.willingToRelocate === 'yes' ? true : false;
        candidateData.problems = candidateData.problems === 'yes' ? 1 : 0;
        candidateData.malpracticeSuits = candidateData.malpracticeSuits === 'yes' ? 1 : 0;
        candidateData.affectPlacement = candidateData.affectPlacement === 'yes' ? 1 : 0;
        candidateData.yearsOfExperience = candidateData.yearsOfExperience ? parseInt(candidateData.yearsOfExperience) : null;
        candidateData.visaTypes_ID = candidateData.visaTypes_ID ? parseInt(candidateData.visaTypes_ID) : null;
        candidateData.currentCompensation = candidateData.currentCompensation?.substring(1) || '';
        candidateData.desiredCompensation = candidateData.desiredCompensation?.substring(1) || '';
        candidateData.candidateComments = draftToHtml(convertToRaw(editorState.getCurrentContent()));

        candidateData.facebook = facebookPrefix + candidateData.facebook;
        candidateData.linkedIn = linkedInPrefix + candidateData.linkedIn;
        candidateData.twitter = twitterPrefix + candidateData.twitter;
        candidateData.url = urlPrefix + candidateData.url;
        candidateData.users_ID = Globals.userInfo.users_ID;



        if (!candidateData.firstName || !candidateData.lastName || !candidateData.specialty || !candidateData.subSpecialty) {
            toast.warning("Please make sure all required fields are filled in");
        } else {

            candidateData.additionalSpecialties = '';
            for (var x = 0; x < specialtyInfo?.selectedAdditionalSpecialties.length; x++) {
                candidateData.additionalSpecialties += specialtyInfo?.selectedAdditionalSpecialties[x].specialties_ID + '|';
            }

            candidateData.preferredStatesToWork = '';
            for (var j = 0; j < preferredStates.length; j++) {
                candidateData.preferredStatesToWork += preferredStates[j] + '|';
            }

            candidateData.jobTypes = '';
            for (var j = 0; j < selectedJobTypes.length; j++) {
                candidateData.jobTypes += selectedJobTypes[j] + '|';
            }

            candidateData.typesOfPractice = '';
            for (var j = 0; j < selectedTypesOfPractice.length; j++) {
                candidateData.typesOfPractice += selectedTypesOfPractice[j] + '|';
            }

            candidateData.licensure = '';
            for (var j = 0; j < selectedLicensureRecords.length; j++) {
                candidateData.licensure += selectedLicensureRecords[j] + '|';
            }

            candidateData.preferredStates = '';
            for (var j = 0; j < selectedRelocationStates.length; j++) {
                candidateData.preferredStates += selectedRelocationStates[j] + '|';
            }

            candidateData.tags = '';
            for (var j = 0; j < selectedTags.length; j++) {
                candidateData.tags += selectedTags[j] + '|';
            }

            if (emailAddresses.length > 0 && emailAddresses[0].emailAddress !== '') {
                for (var j = 0; j < emailAddresses.length; j++) {
                    var strNum = j + 1;
                    candidateData['email' + strNum] = emailAddresses[j].emailAddress;
                    candidateData['email' + strNum + 'Type'] = emailAddresses[j].emailType;
                    candidateData['email' + strNum + 'Preferred'] = emailAddresses[j].preferred;
                    candidateData['email' + strNum + 'DoNotEmail'] = emailAddresses[j].doNotEmail;
                }
            }

            let postdata = {
                candidatesModel: candidateData,
                candidatePhoneNumbers: phoneNumbers,
                s1: '',
                s2: '',
                s3: '',
                workHistoryList: workHistory.filter(wh => wh.isDirty) || null,
                educationHistoryList: educationHistory.filter(eh => eh.isDirty) || null,
                referencesList: references.filter(ref => ref.isDirty) || null,
                certificationsList: selectedCertifications.filter(cert => cert.isDirty) || null,
                id: Globals.userInfo.users_ID
            }

            setUpdating(true);
            toast.loading("Adding Candidate...", { key: 'loading' });
            addCandidate(postdata).then(data => {
                if (data?.dashboardInformation) {

                    let selectedCandidate = {
                        candidates: data.dashboardInformation?.candidate,
                        phoneNumbers
                    }

                    if(resume) {
                        let formdata = new FormData();
                        formdata.append('file', resume);
                        formdata.append('candidates_ID', selectedCandidate.candidates.candidates_ID);
                        formdata.append('fileDescription', '');
                        formdata.append('currentResume', true);
                        if (fileText) formdata.append('rtfContent', fileText);

                        addCandidateAttachment(formdata).then(attData => {
                            if (attData) {
                                updateAfterUpdate(selectedCandidate, data);
                            }

                        }).catch(error => {
                            toast.destroy('loading');
                            toast.error("Something went wrong");
                        });
                    } else {
                        updateAfterUpdate(selectedCandidate, data);
                    }
                    setUpdating(false);
                } else {
                    setUpdating(false);
                    toast.error("Something went wrong");
                }
            }).catch(error => { toast.error("Something went wrong"); setUpdating(false); });
        }
    }

    function updateAfterUpdate(selectedCandidate, data) {
        isDirty.current = false;

        toast.destroy('loading');
        toast.success('Candidate successfully added');

        handleClose();

        navigate(`/candidate-dashboard/${selectedCandidate.candidates.candidates_ID}`,
            {
                replace: true,
                state: { from: 'addCandidate', dashboardInformation: data.dashboardInformation }
            });

        getOrganizationInfo();
    }

    function goToCandidate() {
        let selectedCandidate = {
            candidates: duplicateInfo.chosenDuplicate
        }
        isDirty.current = false;
        Globals.isDirtyPage = false;

        handleClose();
        navigate("/candidate-dashboard",
            {
                replace: true,
                state: { selectedCandidate, from: 'addCandidate' }
            });
    }

    function getSubSpecialtiesByParent(id) {

        let subSpecialties = listData.subSpecialties[id];
        let selectedAdditionalSpecialties = getAdditionalSpecialties(subSpecialties);

        setSpecialtyInfo({
            ...specialtyInfo,
            subSpecialties,
            selectedAdditionalSpecialties
        });
    }

    function getAdditionalSpecialties(subSpecialties, contactInformation = null) {

        let selectedAddSpecs = [];
        let splitArr = [];

        let ci = contactInformation || getValues().formFields;

        splitArr = ci?.selectedAddSpecs?.split('|') || [];

        for (var i = 0; i < splitArr.length; i++) {

            var spec = subSpecialties.find(type => type.specialties_ID === parseInt(splitArr[i]));
            if (spec) {
                selectedAddSpecs.push(spec);
            }
        }

        return selectedAddSpecs
    }

    function addSubSpecialty(value) {
        //e.preventDefault();
        isDirty.current = true;
        // let target = e.target;
        // let name = target.name;
        // let value = target.value;

        let selectedAddSpecs = [...specialtyInfo.selectedAdditionalSpecialties];

        var spec = specialtyInfo.subSpecialties.find(type => type.specialties_ID === parseInt(value));
        if (spec) {
            selectedAddSpecs.push(spec);
        }

        setSpecialtyInfo({
            ...specialtyInfo,
            selectedAdditionalSpecialties: selectedAddSpecs
        });

        setShowAddSubSpecialty(false);
    }

    function addOrRemoveAdditionalSubSpecialty(e, i) {
        e.preventDefault();

        isDirty.current = true;  // dirty
        Globals.isDirtyPage = true;

        switch (e.target.id) {
            case 'add':
                setShowAddSubSpecialty(true);
                break;
            case 'remove':
                let additionalSpecs = [...specialtyInfo.selectedAdditionalSpecialties];

                additionalSpecs.splice(i, 1);

                setSpecialtyInfo({
                    ...specialtyInfo,
                    selectedAdditionalSpecialties: additionalSpecs
                });
                break;
            default:
                break;
        }
    }

    function handleContactInformationChange(event) {
        const target = event.target;
        //var value = target.value.replace(/^\s*[o\W]\s+/g, '');
        var value = target.value;
        const name = target.name;
        const duplicates = candidates.filter(candidate =>
            (((name === 'firstName' && candidate['lastName'].toLowerCase() === getValues().formFields.lastName?.toLowerCase()))) && candidate[name]?.toLowerCase().startsWith(value.toLowerCase()) ||
            (((name === 'lastName' && getValues().formFields.firstName === '') || (name === 'lastName' && getValues().formFields.firstName?.toLowerCase() === getValues().formFields.firstName?.toLowerCase()))) && candidate[name].toLowerCase().startsWith(value.toLowerCase())
        );
        isDirty.current = true;
        //this.setState({
        //    duplicates,
        //    contactInformation: { ...this.state.contactInformation, [name]: value },
        //    chosenDuplicate: duplicates.length === this.state.candidates.length ? null : duplicates[0],
        //    isChosenDuplicate: (duplicates.length !== this.state.candidates.length && duplicates.length > 0) ? true : false
        //}, () => {
        //    if (JSON.stringify(this.state.contactInformation) !== JSON.stringify(this.contactInformation)) {
        //        this.isDirty = true;  // dirty
        //        Globals.isDirtyPage = true;
        //    }

        //});
    }

    function updatePreferredStates(value) {
        setPreferredStates(value);
        isDirty.current = true;
    }

    function updateSelectedJobTypes(value) {
        setSelectedJobTypes(value);
        isDirty.current = true;

        var toShowDuration = false;

        value.forEach(item => {
            let jt = listData.jobTypes.find(j => j.value === item);
            if (jt?.label === 'Contract' || jt?.label === 'Temporary') {
                toShowDuration = true;
            }
        });

        setShowDuration(toShowDuration);
    }

    function updateTypesOfPractice(value) {
        setSelectedTypesOfPractice(value);
        isDirty.current = true;
    }

    function addOrRemovePhoneNumber(e, i) {
        e.preventDefault();

        isDirty.current = true;
        Globals.isDirtyPage = true;

        let pn = phoneNumbers;
        switch (e.target.id) {
            case 'add':

                let numberToAdd = {
                    phoneNumber: '',
                    type: 'work',
                    preferred: false,
                    receiveSMS: false,
                    isDirty: false,
                    show: true
                }

                pn.push(numberToAdd);

                setPhoneNumbers([...pn]);
                break;
            case 'remove':
                var number = phoneNumbers[i];
                if (number.isDirty) {
                    confirm({
                        title: "You are about to delete this phone number.",
                        content: "Are you sure you would like to proceed?",
                        buttons: ["Yes", "No"],
                        theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                    },
                        (ButtonPressed) => {
                            if (ButtonPressed === "Yes") {
                                if (i === 0) {
                                    pn[i].phoneNumber = '';
                                    pn[i].type = 'work';
                                }
                                else {
                                    pn.splice(i, 1);
                                }

                                setPhoneNumbers([...pn]);

                                return 0;
                            }
                            if (ButtonPressed === "No") {
                                return 0;
                            }
                        }
                    );
                }
                else {
                    pn.splice(i, 1);

                    setPhoneNumbers([...pn]);
                }

                break;
            default:
                break;
        }
    }

    function handlePhoneNumberChange(event, i) {
        
        var value = null;
        var target = null;
        var name = null;

        if(event?.target) {
             target = event.target;

            if (target.type === 'checkbox') {
                value = target?.checked
            }
            else {
                value = target?.value;
            }

            name = target?.name;
        } else {
            value = event;
            name = "phoneNumber";
        }
        
        let pn = [...phoneNumbers]

        if (name === 'preferred' && value) {
            pn = pn.map(n => ({ ...n, preferred: false }))
        }

        if (name === 'receiveSMS' && value) {
            pn = pn.map(n => ({ ...n, receiveSMS: false }))
        }
        var number = { ...pn[i] };

        if (target?.type !== 'checkbox' && value?.length === 12 && value?.indexOf(' ') === -1 && phoneNumbers.phoneNumber !== value) {
            //check for uniqueness
            isPhoneNumberUnique(value, 0).then(data => {
                setPhoneNumberNotUnique(!data);
            });
        } else if (!(value?.length >= 12 && value?.indexOf(' ') === -1)) {
            setPhoneNumberNotUnique(false);
        }

        number[name] = value;
        number.isDirty = true;

        pn[i] = number;

        setPhoneNumbers([...pn]);

        isDirty.current = true;
        Globals.isDirtyPage = true;
    }

    function addOrRemoveEmailAddress(e, i) {
        e.preventDefault();

        isDirty.current = true;
        Globals.isDirtyPage = true;

        let emails = emailAddresses;
        switch (e.target.id) {
            case 'add':

                let emailToAdd = emailDefault;

                emails.push(emailToAdd);

                setEmailAddresses([...emails]);
                break;
            case 'remove':
                var email = emailAddresses[i];
                if (email.isDirty) {
                    confirm({
                        title: "You are about to delete this email.",
                        content: "Are you sure you would like to proceed?",
                        buttons: ["Yes", "No"],
                        theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                    },
                        (ButtonPressed) => {
                            if (ButtonPressed === "Yes") {
                                if (i === 0) {
                                    email = emailDefault;
                                    emails[i] = email;
                                }
                                else {
                                    emails.splice(i, 1);
                                }

                                setEmailAddresses([...emails]);

                                return 0;
                            }
                            if (ButtonPressed === "No") {
                                return 0;
                            }
                        }
                    );
                }
                else {
                    emails.splice(i, 1);

                    setEmailAddresses([...emails]);
                }

                break;
            default:
                break;
        }
    }

    function handleEmailAddressChange(event, i) {
        //event.preventDefault();
        const target = event.target;
        var value = null;
        if (target.type === 'checkbox') {
            value = !emailAddresses[i].preferred
        }
        else {
            value = target.value;
        }
        const name = target.name;

        var email = emailAddresses[i];
        email[name] = value;
        email.isDirty = true;
        isDirty.current = true;

        const ea = [...emailAddresses];
        ea[i] = email;

        setEmailAddresses(ea);

        isDirty.current = true;
        Globals.isDirtyPage = true;
    }

    function addOrRemoveCertification(e, i) {
        e.preventDefault();

        let certifications = [...selectedCertifications];
        isDirty.current = true;

        switch (e.target.id) {
            case 'add':

                certifications.push(certificationDefault);

                setSelectedCertifications(certifications);
                break;
            case 'remove':

                var certification = { ...selectedCertifications[i] };
                if (certification.isDirty) {
                    confirm({
                        title: "You are about to delete this certification card.",
                        content: "Are you sure you would like to proceed?",
                        buttons: ["Yes", "No"],
                        theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                    }, (buttonPressed) => {
                        if (buttonPressed === 'Yes') {
                            certifications.splice(i, 1);

                            setSelectedCertifications(certifications);
                            return 0;
                        } else {
                            return 0;
                        }
                    });
                }
                else {
                    certifications.splice(i, 1);
                    setSelectedCertifications(certifications);
                }

                break;
            default:
                break;
        }
    }

    function handleCertificationChange(event, i) {
        event.preventDefault();
        const target = event.target;
        isDirty.current = true;

        var value = null;
        if (target.type === 'checkbox') {
            value = target.checked === true ? 1 : 0;
        }
        else {
            value = target.value;
        }
        const name = target.name;

        var cert = { ...selectedCertifications[i] };
        cert[name] = value;
        cert.isDirty = true;
        Globals.isDirtyPage = true;

        const certifications = [...selectedCertifications];
        certifications[i] = cert;

        setSelectedCertifications(certifications);
    }

    function updateSelectedLicensures(value) {
        isDirty.current = true;
        setSelectedLicensureRecords(value);
    }

    function updateRelocationStates(value) {
        isDirty.current = true;
        setSelectedRelocationStates(value);
    }

    function addOrRemoveEducationHistory(e, i) {
        e.preventDefault();

        let educations = [...educationHistory];
        isDirty.current = true;

        switch (e.target.id) {
            case 'add':

                educations.push(educationDefault);

                setEducationHistory(educations);
                break;
            case 'remove':

                var education = { ...educationHistory[i] };
                if (education.isDirty) {
                    confirm({
                        title: "You are about to delete this education history item.",
                        content: "Are you sure you would like to proceed?",
                        buttons: ["Yes", "No"],
                        theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                    }, (buttonPressed) => {
                        if (buttonPressed === 'Yes') {
                            educations.splice(i, 1);

                            setEducationHistory(educations);
                            return 0;
                        } else {
                            return 0;
                        }
                    });
                }
                else {
                    educations.splice(i, 1);
                    setEducationHistory(educations);
                }

                break;
            default:
                break;
        }
    }

    function handleEducationHistoryChange(event, i) {
        const target = event.target;
        isDirty.current = true;

        var value = null;
        if (target.type === 'checkbox') {
            value = target.checked;
        }
        else {
            value = target.value;
        }
        const name = target.name;

        var education = { ...educationHistory[i] };
        education[name] = value;
        education.isDirty = true;
        Globals.isDirtyPage = true;

        const educations = [...educationHistory];
        educations[i] = education;

        setEducationHistory(educations);
    }

    function handleEducationHistoryDateChange(date, dateName, i) {

        var history = educationHistory[i];
        history[dateName] = date;
        history.isDirty = true;
        Globals.isDirtyPage = true;

        var eh = [...educationHistory];
        eh[i] = history;

        setEducationHistory(eh);
    }

    function addOrRemoveWorkHistory(e, i) {
        e.preventDefault();

        let history = [...workHistory];
        isDirty.current = true;

        switch (e.target.id) {
            case 'add':

                history.push(workHistoryDefault);

                setWorkHistory(history);
                break;
            case 'remove':

                var historyItem = { ...workHistory[i] };
                if (historyItem.isDirty) {
                    confirm({
                        title: "You are about to delete this work history item.",
                        content: "Are you sure you would like to proceed?",
                        buttons: ["Yes", "No"],
                        theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                    }, (buttonPressed) => {
                        if (buttonPressed === 'Yes') {
                            history.splice(i, 1);

                            setWorkHistory(history);
                            return 0;
                        } else {
                            return 0;
                        }
                    });
                }
                else {
                    history.splice(i, 1);
                    setWorkHistory(history);
                }

                break;
            default:
                break;
        }
    }

    function handleWorkHistoryChange(event, i) {
        const target = event.target;
        isDirty.current = true;

        var value = null;
        if (target.type === 'checkbox') {
            value = target.checked;
        }
        else {
            value = target.value;
        }
        const name = target.name;

        var historyItem = { ...workHistory[i] };
        historyItem[name] = value;
        historyItem.isDirty = true;
        Globals.isDirtyPage = true;

        const history = [...workHistory];
        history[i] = historyItem;

        setWorkHistory(history);
    }

    function handleWorkHistoryDateChange(date, dateName, i) {

        var historyItem = workHistory[i];
        historyItem[dateName] = date;
        historyItem.isDirty = true;
        Globals.isDirtyPage = true;

        var history = [...workHistory];
        history[i] = historyItem;

        setWorkHistory(history);
    }

    function addOrRemoveReference(e, i) {
        e.preventDefault();

        let theseReferences = [...references];
        isDirty.current = true;

        switch (e.target.id) {
            case 'add':

                theseReferences.push(referenceDefault);

                setReferences(theseReferences);
                break;
            case 'remove':

                var referenceItem = { ...references[i] };
                if (referenceItem.isDirty) {
                    confirm({
                        title: "You are about to delete this work reference item.",
                        content: "Are you sure you would like to proceed?",
                        buttons: ["Yes", "No"],
                        theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                    }, (buttonPressed) => {
                        if (buttonPressed === 'Yes') {
                            theseReferences.splice(i, 1);

                            setReferences(theseReferences);
                            return 0;
                        } else {
                            return 0;
                        }
                    });
                }
                else {
                    theseReferences.splice(i, 1);
                    setReferences(theseReferences);
                }

                break;
            default:
                break;
        }
    }

    function handleReferenceChange(event, i) {
        const target = event.target;
        isDirty.current = true;

        var value = null;
        if (target.type === 'checkbox') {
            value = target.checked;
        }
        else {
            value = target.value;
        }
        const name = target.name;

        var referenceItem = { ...references[i] };
        referenceItem[name] = value;
        referenceItem.isDirty = true;
        Globals.isDirtyPage = true;

        const theseReferences = [...references];
        theseReferences[i] = referenceItem;

        setReferences(theseReferences);
    }

    function updateTags(value) {
        isDirty.current = true;
        setSelectedTags(value);
    }

    function handleAdditionalSubSpecialtyChange(value, i) {
        // const target = event.target;
        // const name = target.name;
        // var value = target.value;

        let spec = specialtyInfo.subSpecialties.find(ss => ss.specialties_ID === parseInt(value));

        if (spec) {
            let selectedAdditionalSpecialties = [...specialtyInfo.selectedAdditionalSpecialties];
            selectedAdditionalSpecialties[i] = spec;

            setSpecialtyInfo({
                ...specialtyInfo,
                selectedAdditionalSpecialties
            });
        }
    }

    function createTag(e) {

        if (e.keyCode === 13 && e.target.id === 'tags') {
            if (!listData.orgTags.find(tg => tg.label.includes(e.target.value))) {
                //let postdata = {
                //    tagsModel: {}
                //};

                //postdata.tagsModel.tag = e.target.value;
                //postdata.tagsModel.organizations_ID = Globals.userInfo.organizations_ID;

                let tag = {
                    tag: e.target.value,
                    organizations_ID: Globals.userInfo.organizations_ID
                }

                insertTag(tag).then(data => {
                    if (data === 'duplicate') {
                        toast.error("This tag already exists");
                    } else {
                        let st = [...selectedTags];
                        let tagsList = [...listData.unModifiedTags];

                        let tags_ID = data;

                        let t = {
                            tags_ID,
                            organizations_ID: Globals.userInfo.organizations_ID,
                            tag: e.target.value
                        };

                        st.push(tags_ID);
                        tagsList.push(t);

                        setSelectedTags([...st]);
                        orgTags.next(tagsList);
                        setActiveOrgTags(tagsList);

                        //setUpdate(!update);
                        e.target.value = '';

                        isDirty.current = true;
                    }
                }).catch(error => {
                    toast.error("Something went wrong");
                });
            }
        }

    }

    function createSpecialty(e) {

        let id = e.target.id;
        let value = e.target.value;

        if (e.keyCode === 13 && (id === 'subSpecialty' || id === 'specialty') && value) {
            if ((!specialtyInfo.subSpecialties?.find(spec => spec.specialty.toLowerCase().includes(e.target.value.toLowerCase())) && id === 'subSpecialty') ||
                (!listData.parentSpecialties?.find(spec => spec.specialty.toLowerCase().includes(e.target.value.toLowerCase())) && id === 'specialty')) {

                let specialty = {
                    specialty: value,
                    organizations_ID: Globals.userInfo.organizations_ID
                };

                if (id === 'subSpecialty') {
                    specialty.parentID = parseInt(getValues().formFields.specialty);
                }

                insertSpecialty(specialty).then(data => {
                    if (data === -1) {
                        toast.error("This specialty already exists");
                    } else {

                        specialty.specialties_ID = data;

                        if (id === 'subSpecialty') {
                            let subSpecList = specialtyInfo.subSpecialties?.length > 0 ? [...specialtyInfo.subSpecialties] : [];

                            subSpecList.push(specialty);

                            let localActiveSubSpecialties = { ...activeSubSpecialties };
                            localActiveSubSpecialties[getValues().formFields.specialty] = subSpecList;

                            setValue('formFields.subSpecialty', data);

                            subSpecialties.next(localActiveSubSpecialties);

                            setSpecialtyInfo({
                                ...specialtyInfo,
                                subSpecialties: subSpecList
                            });

                            setActiveSubSpecialties(localActiveSubSpecialties);
                        } else if (id === 'specialty') {
                            let localParentSpecialties = [...listData.parentSpecialties];

                            setValue('formFields.specialty', data);
                            localParentSpecialties.push(specialty);
                            setListData({
                                ...listData,
                                localParentSpecialties
                            });

                            parentSpecialties.next(localParentSpecialties);
                            setActiveParentSpecialties(localParentSpecialties);
                        }

                        e.target.value = '';

                        isDirty.current = true;
                    }
                }).catch(error => {
                    console.log(error);
                    toast.error("Something went wrong");
                });
            }
        }

    }

    async function parseResume() {
        setSeeThroughActive(false);
        parsingModal.current = Modal.info({
            wrapClassName: 'parse-resume-modal',
            title: 'Extracting Candidate Data',
            content: <>
                <Spin tip="Parsing Resume...">
                    <Alert
                        message="Please be patient while this completes"
                        description="This may take a few moments"
                        type="info"
                    />
                </Spin>
            </>,
            width: 800,
        });

        let formdata = new FormData();
        formdata.append('Resume', resume);

        const response = await axios.post(`https://api.edenai.run/v2/workflow/1a3afe9c-f1ed-4231-bff7-1a40ceb2c3ab/execution/`, formdata, {
            headers: {
                //Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNDYxYTY3NWUtMDEyZS00MDk1LTk4MDEtNjBkOTRhYzNhY2RiIiwidHlwZSI6InNhbmRib3hfYXBpX3Rva2VuIn0.fiai7aWqF1bJHi-KKxHUa6YI0uHei4ijD0Mslp7lJ1c",
                Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNDYxYTY3NWUtMDEyZS00MDk1LTk4MDEtNjBkOTRhYzNhY2RiIiwidHlwZSI6ImFwaV90b2tlbiJ9.BkaNbZxfmZ_ekl0YWQI0CWwvTyftUCDQSYJamaJShhY",
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
            },
        });

        let orgToUpdate = {...orgInfo};
        orgToUpdate.availableResumeParses = orgToUpdate.availableResumeParses - 1;

        updateOrganization(orgToUpdate).then(data => { 
            if(data) {
                setOrgInfo(orgToUpdate);
            }
        });

        getResumeParseExecution(response.data.id);
    }

    async function getResumeParseExecution(executionId) {
        const response = await axios.get(`https://api.edenai.run/v2/workflow/1a3afe9c-f1ed-4231-bff7-1a40ceb2c3ab/execution/${executionId}/`, {
            headers: {
                //Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNDYxYTY3NWUtMDEyZS00MDk1LTk4MDEtNjBkOTRhYzNhY2RiIiwidHlwZSI6InNhbmRib3hfYXBpX3Rva2VuIn0.fiai7aWqF1bJHi-KKxHUa6YI0uHei4ijD0Mslp7lJ1c",
                Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiNDYxYTY3NWUtMDEyZS00MDk1LTk4MDEtNjBkOTRhYzNhY2RiIiwidHlwZSI6ImFwaV90b2tlbiJ9.BkaNbZxfmZ_ekl0YWQI0CWwvTyftUCDQSYJamaJShhY",
                Accept: "application/json",
            },
        });

        let getRes = response.data;
        if (getRes?.content?.status === 'running') {
            setTimeout(() => {
                getResumeParseExecution(getRes.id);
            }, 3000);
        } else if (getRes?.content?.status === 'success') {
            var parsedResume = getRes?.content?.results?.output?.results[0]?.extracted_data;
            setResumeParsed(true);
            //var parsedResume = sampleCandidate;
            manipulateCandidateFromResume(parsedResume);
        } else if (getRes?.content?.status === 'error') {
            toast.error("Something went wrong while parsing the resume");
            parsingModal?.current?.destroy();
        }

        return response.data;
    }

    function manipulateCandidateFromResume(parsedResume) {
        isDirty.current = true;
        parsingModal?.current?.destroy();

        var state = formFieldsDefault.state;
        var country = formFieldsDefault.country;
        if (parsedResume?.personal_infos?.address?.region) {

            country = parsedResume?.personal_infos?.address?.country_code;
            setSelectedCountry(country);

            var states = statesList.filter(obj => {
                return obj.country_code === parsedResume?.personal_infos?.address?.country_code
            });

            var stateObj = states.find(ml => ml.name === parsedResume?.personal_infos?.address?.region);
            state = stateObj ? stateObj.state_code : state;
        }

        let formFields = {
            firstName: parsedResume?.personal_infos?.name?.first_name || formFieldsDefault.firstName,
            lastName: parsedResume?.personal_infos?.name?.last_name || formFieldsDefault.lastName,
            middleName: parsedResume?.personal_infos?.name?.middle || formFieldsDefault.middleName,
            country,
            city: parsedResume?.personal_infos?.address?.city || formFieldsDefault.city,
            state: state,
            zip: parsedResume?.personal_infos?.address?.postal_code || formFieldsDefault.zip,
        }


        for (const [key, value] of Object.entries(formFields)) {
            if (getValues(`formFields.${key}`) !== value) {
                setValue(`formFields.${key}`, value, { shouldDirty: true });
            }
        }

        if(parsedResume?.personal_infos?.self_summary) {
            //this.setState({editorState: this.insertText(text, this.state.editorState)});
            setEditorState(EditorState.createWithContent(ContentState.createFromText(parsedResume?.personal_infos?.self_summary)));
        }

        if (parsedResume?.personal_infos?.phones) {
            let localPhoneNumbers = [...phoneNumbers];

            parsedResume.personal_infos.phones.forEach((pn, idx) => {
                let pnForApi = pn.replace(/[^+\d]+/g, "");
                isPhoneNumberUnique(pnForApi, 0).then(data => {
                    if(!data) {
                        setPhoneNumberNotUnique(true);
                    }
                    
                });

                let numberToAdd = {
                    phoneNumber: pn,
                    type: 'work',
                    preferred: false,
                    receiveSMS: false,
                    isDirty: true,
                    show: true,
                    imported: true
                }

                if (!localPhoneNumbers[idx]?.phoneNumber) {
                    localPhoneNumbers[idx] = numberToAdd;
                } else {
                    localPhoneNumbers.push(numberToAdd);
                }
            });

            setPhoneNumbers([...localPhoneNumbers]);
        }

        if (parsedResume?.personal_infos?.mails) {
            let localEmailAddresses = [...emailAddresses];
            parsedResume.personal_infos.mails.forEach((email, idx) => {
                let emailToAdd = {
                    emailAddress: email,
                    emailType: 'work',
                    preferred: false,
                    doNotEmail: false,
                    isDirty: true,
                    show: true,
                    imported: true
                }

                if (!localEmailAddresses[idx]?.emailAddress) {
                    localEmailAddresses[idx] = emailToAdd;
                } else {
                    localEmailAddresses.push(emailToAdd);
                }
            });

            setEmailAddresses([...localEmailAddresses]);
        }

        if (parsedResume?.work_experience) {

            var totalYears = parsedResume.work_experience?.total_years_experience;
            var intTotalYears = parseInt(totalYears);

            if (intTotalYears >= 0) {
                setValue(`formFields.yearsOfExperience`, intTotalYears, { shouldDirty: true });
            }

            let localWorkHistory = [...workHistory];
            parsedResume.work_experience.entries?.forEach((we, idx) => {

                let startDate = we.start_date ? new Date(we.start_date) : null;
                let endDate = we.end_date ? new Date(we.end_date) : null;

                if (startDate) {
                    startDate.setMonth(startDate.getMonth() + 1);
                }
                if (endDate) {
                    endDate.setMonth(endDate.getMonth() + 1);
                }

                let workToAdd = {
                    title: we.title || '',
                    company: we.company || '',
                    startDate,
                    endDate,
                    workType: we.type || '',
                    description: we.description || '',
                    isCurrent: we.end_date === null,
                    imported: true,
                    isDirty: true
                }

                if (!localWorkHistory[idx]?.title) {
                    localWorkHistory[idx] = workToAdd;
                } else {
                    localWorkHistory.push(workToAdd);
                }
            });

            setWorkHistory([...localWorkHistory]);
        }

        if (parsedResume?.certifications) {
            let localCertifications = [...selectedCertifications];
            parsedResume.certifications.forEach((cert, idx) => {
                let certToAdd = {
                    name: cert.name || '',
                    isDirty: true,
                    imported: true
                }

                if (!localCertifications[idx]?.name) {
                    localCertifications[idx] = certToAdd;
                } else {
                    localCertifications.push(certToAdd);
                }
            });

            setSelectedCertifications([...localCertifications]);
        }

        if (parsedResume?.education) {
            let localEducationHistory = [...educationHistory];
            parsedResume.education?.entries?.forEach((edu, idx) => {

                let endDate = edu.end_date ? new Date(edu.end_date) : null;
                if (endDate) {
                    endDate.setMonth(endDate.getMonth() + 1);
                }

                let educationToAdd = {
                    schoolName: edu.establishment || '',
                    degree: edu.title || '',
                    schoolDate: endDate,
                    isCurrent: edu.end_date === null,
                    imported: true,
                    isDirty: true
                }

                if (!localEducationHistory[idx]?.school) {
                    localEducationHistory[idx] = educationToAdd;
                } else {
                    localEducationHistory.push(educationToAdd);
                }
            });

            setEducationHistory([...localEducationHistory]);
        }

        setShowParseBtn(false);
    }

    function onEditorStateChange(es) {
        isDirty.current = true;
        setEditorState(es);
    }

    const saveDisabled = phoneNumberNotUnique;

    function getPropByString(obj, propString) {
        if (!propString)
            return obj;

        var prop, props = propString.split('.');

        for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
            prop = props[i];

            var candidate = obj[prop];
            if (candidate !== undefined) {
                obj = candidate;
            } else {
                break;
            }
        }
        return obj[props[i]];
    }

    function Child({ control, field }) {
        const { dirtyFields } = useFormState({
            control
        });

        return getPropByString(dirtyFields, field) && resumeParsed ? <HighlightSpan /> : null;
    };

    function HighlightSpan() {
        return <span className="highlight-field"></span>
    }

    return (
        <Drawer
            closeIcon={<i className="fas fa-chevron-left float-left"></i>}
            title='Add Candidate'
            placement={'right'}
            onClose={handleClose}
            open={show}
            key='slider'
            width='100%'
            footer={
                <div className="d-flex justify-content-between" key="1">
                    
                    {resume && showParseBtn &&
                        <div>
                            {seeThroughActive &&
                                <div style={{"right":"0","height":"100%","position":"absolute","top":"0","left":"-10px","background":"rgba(0, 0, 0, .4)"}} onClick={() => setSeeThroughActive(false)} />
                            }
                            <Tooltip title="Parse this resume next" color='blue' open={seeThroughActive}>
                                <div style={{"background":"white","zIndex":"11111","position":"fixed"}}>
                                    <button className="ant-btn ant-btn-primary m-1" onClick={parseResume} disabled={saveDisabled} title={saveDisabled ? 'Your data has errors' : ''}>Parse Resume</button>
                                </div>
                            </Tooltip>
                        </div>
                    }
                    <div className="text-center">
                        {updating ? <Spin /> : <button className="ant-btn ant-btn-primary m-1" onClick={handleSubmit} disabled={saveDisabled} title={saveDisabled ? 'Your data has errors' : ''}>Save</button>}
                        <button className="ant-btn ant-btn-default m-1" onClick={handleClose}>Close</button>
                    </div>
                </div>
            }
            className="candidate"
        >
            <div className="row">
                <div className="col col-lg-6 col-12 position-relative">
                    {(userInfo?.emailAddress === 'ricky@landino.dev' || userInfo?.emailAddress === 'johnfrey@nchcr.com' || userInfo?.emailAddress === 'jim@nchcr.com') &&
                    <div className="position-fixed" style={{ width: 'inherit', height: 'calc(100% - 65px)', left: 0 }}>
                        <ImportCandidateResume 
                            resume={resume} 
                            setResume={(res, text) => { 
                                if((orgInfo?.limitResumeParses && orgInfo?.availableResumeParses > 0) || !orgInfo?.limitResumeParses) {
                                    setShowParseBtn(true);
                                }
                                setResume(res); 
                                setSeeThroughActive(true); 
                                setFileText(text) 
                            }
                        } />
                    </div>
                    }
                </div>
                <div className="col col-lg-6 col-12">
                    <Card title="Candidate Information" className="mb-3">
                        <div className="row">
                            {duplicateInfo.isChosenDuplicate &&
                                <div className="col-12">
                                    <Alert className="col-12 mb-3" message={<><strong>Possible Duplicate Entry.</strong> A <em>{duplicateInfo.chosenDuplicate?.firstName} {duplicateInfo.chosenDuplicate?.lastName}</em> already exists. <span className="a hover" onClick={goToCandidate}>Click to view candidate.</span></>} type="warning" showIcon />
                                </div>
                            }

                            <div className="form-group col-lg-4 col-12">
                                <label>First Name <span className="text-danger">*</span></label>
                                <Child control={control} field="formFields.firstName" />
                                <input maxLength="100" className="ant-input" {...register('formFields.firstName')} />
                            </div>

                            <div className="form-group col-lg-4 col-12">
                                <label>Middle Name</label>
                                <Child control={control} field="formFields.middleName" />
                                <input maxLength="36" className="ant-input" {...register('formFields.middleName')} />
                            </div>

                            <div className="form-group col-lg-4 col-12">
                                <label>Last Name <span className="text-danger">*</span></label>
                                <Child control={control} field="formFields.lastName" />
                                <input maxLength="100" className="ant-input" {...register('formFields.lastName')} />
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label>Specialty <span className="text-danger">*</span></label>
                                {listData?.parentSpecialties?.length > 0 ?
                                    <Controller
                                        control={control}
                                        name="formFields.specialty"
                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                            <Select
                                                id="specialty"
                                                style={{
                                                    width: '100%',
                                                }}
                                                placeholder="Please select"
                                                value={value}
                                                onChange={onChange}
                                                showSearch
                                                options={listData.parentSpecialties}
                                                filterOption={(input, option) => (option?.specialty?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                                optionFilterProp='specialty'
                                                fieldNames={{ label: 'specialty', value: 'specialties_ID' }}
                                                onInputKeyDown={createSpecialty}
                                                notFoundContent='Press enter to create a new specialty for your organization'
                                            />
                                        }
                                    />
                                    :
                                    <p>Issue loading specialties</p>
                                }
                            </div>

                            {/* <div className="form-group col-lg-6 col-12">
                                <label>Specialty <span className="text-danger">*</span></label>
                                {listData?.parentSpecialties?.length > 0 ?
                                    <select className="ant-input" {...register('formFields.specialty')}>
                                        <option></option>
                                        {listData.parentSpecialties.map((spec, idx) => <option key={idx} value={spec.specialties_ID}>{spec.specialty}</option>)}
                                    </select>
                                    :
                                    <p>Issue loading specialties</p>
                                }
                            </div> */}

                            <div className="form-group col-lg-6 col-12">
                                {/* <label className="w-100">Sub Specialty <span className="text-danger">*</span>
                                    {showAddSubSpecialty ?
                                        <i className="far fa-window-close candidate-text float-end mt-2 hover" id="add" onClick={() => setShowAddSubSpecialty(false)} />
                                        :
                                        <span className="float-end hover candidate-text" id="add" onClick={addOrRemoveAdditionalSubSpecialty}>Add Sub Specialty &nbsp; <i className="far fa-plus-square candidate-text float-end mt-2" id="add" onClick={addOrRemoveAdditionalSubSpecialty} /></span>
                                    }
                                </label> */}

                                <label className="w-100">Sub Specialty <span className="text-danger">*</span>
                                    {showAddSubSpecialty ?
                                        <i className="far fa-window-close candidate-text float-end mt-2 hover" id="add" onClick={() => setShowAddSubSpecialty(false)} />
                                        :
                                        <span className="float-end hover candidate-text" id="add" onClick={addOrRemoveAdditionalSubSpecialty}>Add Sub Specialty &nbsp; <i className="far fa-plus-square candidate-text float-end mt-2" id="add" onClick={addOrRemoveAdditionalSubSpecialty} /></span>
                                    }
                                </label>
                                {(specialtyInfo.subSpecialties?.length > 0 || getValues()?.formFields?.specialty) ?
                                    <Controller
                                        control={control}
                                        name="formFields.subSpecialty"
                                        render={({ field: { onChange, onBlur, value, ref } }) =>
                                            <Select
                                                style={{
                                                    width: '100%',
                                                }}
                                                id="subSpecialty"
                                                placeholder="Please select"
                                                value={value}
                                                onChange={onChange}
                                                showSearch
                                                options={specialtyInfo.subSpecialties}
                                                filterOption={(input, option) => (option?.specialty?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                                optionFilterProp='specialty'
                                                fieldNames={{ label: 'specialty', value: 'specialties_ID' }}
                                                onInputKeyDown={createSpecialty}
                                                notFoundContent='Press enter to create a new sub specialty for your organization'
                                            />
                                        }
                                    />

                                    :
                                    <p><em>Please select a specialty first</em></p>
                                }

                                {/* {specialtyInfo.subSpecialties?.length > 0 ?
                                    <select className="ant-input" {...register('formFields.subSpecialty')}>
                                        <option></option>
                                        {specialtyInfo.subSpecialties.map((spec, idx) => <option key={idx} value={spec.specialties_ID}>{spec.specialty}</option>)}
                                    </select>
                                    :
                                    <p><em>Please select a specialty first</em></p>
                                } */}

                                {specialtyInfo.selectedAdditionalSpecialties?.map((ss, i) =>
                                    <Fragment key={i}>
                                        <label className="w-100">Additonal Sub Specialty <i id="remove" className=" far fa-minus-square icon float-end mt-3 hover" title="Remove" onClick={(e) => addOrRemoveAdditionalSubSpecialty(e, i)} /></label>
                                        {/* <select className="ant-input" value={ss.specialties_ID || ''} name="specialty" onChange={handleContactInformationChange}>
                                            <option></option>
                                            {specialtyInfo.subSpecialties.map((spec, idx) => <option key={idx} value={spec.specialties_ID}>{spec.specialty}</option>)}
                                        </select> */}
                                        <Select
                                            id="additionalSubs"
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Please select"
                                            value={ss.specialties_ID || ''}
                                            onChange={(value) => handleAdditionalSubSpecialtyChange(value, i)}
                                            showSearch
                                            options={specialtyInfo.subSpecialties}
                                            filterOption={(input, option) => (option?.specialty?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                            optionFilterProp='specialty'
                                            fieldNames={{ label: 'specialty', value: 'specialties_ID' }}
                                        />
                                    </Fragment>
                                )}

                                {showAddSubSpecialty &&
                                    <Fragment>
                                        <label className="w-100">Additonal Sub Specialty</label>
                                        <Select
                                            id="addAdditionalSubs"
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Please select"
                                            value={''}
                                            onChange={addSubSpecialty}
                                            showSearch
                                            options={specialtyInfo.subSpecialties}
                                            filterOption={(input, option) => (option?.specialty?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                            optionFilterProp='specialty'
                                            fieldNames={{ label: 'specialty', value: 'specialties_ID' }}
                                        />
                                        {/* <select className="ant-input" value='' onChange={addSubSpecialty}>
                                            <option></option>
                                            {specialtyInfo.subSpecialties?.map((spec, idx) => <option key={idx} value={spec.specialties_ID}>{spec.specialty}</option>)}
                                        </select> */}
                                    </Fragment>
                                }
                            </div>

                            <div className="form-group col-lg-4 col-12">
                                <label>Preferred States</label>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Please select"
                                    value={preferredStates}
                                    onChange={updatePreferredStates}
                                    options={modifiedStatesList}
                                    optionFilterProp='label'
                                />
                            </div>

                            <div className="form-group col-lg-4 col-12">
                                <label>Job Types</label>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Please select"
                                    value={selectedJobTypes}
                                    onChange={updateSelectedJobTypes}
                                    options={listData.jobTypes}
                                    optionFilterProp='label'
                                />
                            </div>

                            <div className="form-group col-lg-4 col-12">
                                {showDuration &&
                                    <>
                                        <label>Duration</label>
                                        <input maxLength="255" {...register('formFields.duration')} className="ant-input" />
                                    </>
                                }
                            </div>

                            <div className="form-group col-lg-4 col-12">
                                <label>When Available?</label>
                                <input maxLength="500" {...register('formFields.whenAvailable')} className="ant-input" />
                            </div>

                            <div className="form-group col-lg-4 col-12">
                                <label>In-House ID #</label>
                                <input maxLength="100" {...register('formFields.inHouseID')} className="ant-input" />
                            </div>

                            {Globals.userInfo.userType === 'OrgAdmin' &&
                                <div className="form-group col-lg-4 col-12">
                                    <label className="d-block">Confidential Candidate</label>
                                    <input type="checkbox" className="custom-checkbox" {...register('formFields.confidential')} />
                                </div>
                            }

                            <div className="form-group col-12">
                                <label>Candidate Qualifications/Special Requirements</label>
                                <textarea maxLength="2000" type="text" {...register('formFields.specialRequirements')} className="ant-input" />
                            </div>

                            <div className="horizontal-candidates-divider" />

                            {phoneNumberNotUnique &&
                                <Alert className="col-12 mb-3" message="This number is already in use. Please select another." type="error" showIcon />
                            }
                            {phoneNumbers.map((number, i) =>
                                <div className="col-12" key={i}>
                                    {number.show &&
                                        <div className="row">
                                            <>
                                                <MediaQuery maxWidth={1223}>
                                                    <div className="form-group col-12">
                                                        <div className="padding-top-2rem">
                                                            <span id="remove" onClick={(e) => addOrRemovePhoneNumber(e, i)}><i id="remove" className="far fa-minus-square icon float-end hover icon-sm" /></span>
                                                            {(i === 0 && !phoneNumbers[4]?.show) && <span id="add" onClick={addOrRemovePhoneNumber}><i className="far fa-plus-square icon me-2 candidate-text float-end hover icon-sm" id="add" /></span>}

                                                        </div>
                                                    </div>
                                                </MediaQuery>
                                            </>
                                            <div className="form-group col-lg-4 col-12">
                                                <label>Phone</label>
                                                {number.imported && <HighlightSpan />}
                                                {/* <PatternFormat className="ant-input" name="phoneNumber" value={number.phoneNumber || ''} onChange={(e) => handlePhoneNumberChange(e, i)} format="###-###-####" /> */}
                                                <PhoneInput
                                                    numberInputProps={{
                                                        className: 'ant-input'
                                                    }}
                                                    name="phoneNumber"
                                                    defaultCountry={selectedCountry}
                                                    value={number.phoneNumber || ''}
                                                    onChange={(e) => handlePhoneNumberChange(e, i)}
                                                />

                                            </div>
                                            <div className="form-group col-lg-3 col-12">
                                                <label>Type</label>
                                                <select className="ant-input" value={number.type || ''} name="type" onChange={(e) => handlePhoneNumberChange(e, i)}>
                                                    <option value="work">Work</option>
                                                    <option value="mobile">Mobile</option>
                                                    <option value="fax">Fax</option>
                                                    <option value="pager">Pager</option>
                                                    <option value="other">Other</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-2 col-6">
                                                <label className="d-block">Preferred?</label>
                                                <input type="checkbox" className="custom-checkbox" name="preferred" onChange={(e) => handlePhoneNumberChange(e, i)} checked={number.preferred || false} />
                                            </div>
                                            <div className="col-lg-2 col-6">
                                                <label className="d-block">Use for SMS</label>
                                                <input type="checkbox" className="custom-checkbox" name="receiveSMS" onChange={(e) => handlePhoneNumberChange(e, i)} checked={number.receiveSMS || false} />
                                            </div>

                                            <>
                                                <MediaQuery minWidth={1224}>
                                                    <div className="form-group col-1">
                                                        <div className="padding-top-2rem">
                                                            <span id="remove" onClick={(e) => addOrRemovePhoneNumber(e, i)}><i id="remove" className="far fa-minus-square icon float-end hover icon-sm" /></span>
                                                            {(i === 0 && !phoneNumbers[4]?.show) && <span id="add" onClick={addOrRemovePhoneNumber}><i className="far fa-plus-square icon me-2 candidate-text float-end hover icon-sm" id="add" /></span>}

                                                        </div>
                                                    </div>
                                                </MediaQuery>
                                            </>

                                        </div>
                                    }
                                </div>
                            )}

                            <div className="horizontal-candidates-divider" />

                            {emailAddresses.map((email, i) =>
                                <div className="col-12" key={i}>
                                    {email.show &&
                                        <div className="row">
                                            <>
                                                <MediaQuery maxWidth={1223}>
                                                    <div className="form-group col-12">
                                                        <div className="padding-top-2rem">
                                                            <i id="remove" className="far fa-minus-square icon float-end hover icon-sm" onClick={(e) => addOrRemoveEmailAddress(e, i)} />
                                                            {(i === 0 && !emailAddresses[1]?.show) && <i className="far fa-plus-square icon me-2 candidate-text float-end hover icon-sm" id="add" onClick={addOrRemoveEmailAddress} />}

                                                        </div>
                                                    </div>
                                                </MediaQuery>
                                            </>
                                            <div className="form-group col-lg-4 col-12">
                                                <label>Email</label>
                                                {email.imported && <HighlightSpan />}
                                                <input maxLength="100" type="email" className="ant-input" name="emailAddress" value={email.emailAddress || ''} onChange={(e) => handleEmailAddressChange(e, i)} />
                                            </div>
                                            <div className="form-group col-lg-4">
                                                <label>Type</label>
                                                <select className="ant-input" value={email.emailType || ''} name="emailType" onChange={(e) => handleEmailAddressChange(e, i)}>
                                                    <option value='primary'>Primary</option>
                                                    <option value='other'>Other</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-2">
                                                <label className="d-block">Preferred?</label>
                                                <input type="checkbox" className="custom-checkbox" name="preferred" onChange={(e) => handleEmailAddressChange(e, i)} checked={email.preferred} />

                                            </div>
                                            <>
                                                <MediaQuery minWidth={1224}>
                                                    <div className="form-group col-2">
                                                        <div className="padding-top-2rem">
                                                            <i id="remove" className="far fa-minus-square icon float-end hover icon-sm" onClick={(e) => addOrRemoveEmailAddress(e, i)} />
                                                            {(i === 0 && !emailAddresses[1]?.show) && <i className="far fa-plus-square icon me-2 candidate-text float-end hover icon-sm" id="add" onClick={addOrRemoveEmailAddress} />}

                                                        </div>
                                                    </div>
                                                </MediaQuery>
                                            </>
                                        </div>
                                    }
                                </div>
                            )}

                            <div className="horizontal-candidates-divider" />

                            <div className="form-group col-lg-12">
                                <label>Country</label>
                                <Child control={control} field="formFields.country" />
                                <select className="ant-input" {...register('formFields.country')} >
                                    {countryStatesList.map((country, idx) => <option key={idx} value={country.iso2}>{country.name}</option>)}
                                </select>
                            </div>
                            <div className="form-group col-lg-6 col-12">
                                <label>Address</label>
                                <Child control={control} field="formFields.address1" />
                                <input maxLength="100" type="text" {...register('formFields.address1')} className="ant-input" />
                            </div>
                            <div className="form-group col-lg-6 col-12">
                                <label>Address 2</label>
                                <input maxLength="100" type="text" {...register('formFields.address2')} className="ant-input" />
                            </div>
                            <div className="form-group col-lg-6 col-12">
                                <label>City</label>
                                <Child control={control} field="formFields.city" />
                                <input maxLength="100" type="text" {...register('formFields.city')} className="ant-input" />
                            </div>
                            <div className="form-group col-lg-3 col-12">
                                <label>State</label>
                                <Child control={control} field="formFields.state" />
                                <select className="ant-input" {...register('formFields.state')}>
                                    <option></option>
                                    {modifiedStatesList.map((state, idx) => <option key={idx} value={state.value}>{state.label}</option>)}
                                </select>
                            </div>
                            <div className="form-group col-lg-3 col-12">
                                <label>Zip</label>
                                <Child control={control} field="formFields.zip" />
                                <input maxLength="20" type="text" {...register('formFields.zip')} className="ant-input" />
                            </div>
                            <div className="form-group col-lg-6 col-12">
                                <label>Years of Experience</label>
                                <Child control={control} field="formFields.yearsOfExperience" />
                                <input maxLength="2" type="number" {...register('formFields.yearsOfExperience')} className="ant-input" />
                            </div>
                            <div className="form-group col-lg-6">
                                <label>Spoken Languages</label>
                                <input maxLength="500" type="text" {...register('formFields.languages')} className="ant-input" />
                            </div>
                            <div className="form-group col-lg-6 col-12">
                                <label>Visa Type</label>
                                <select className="ant-input" {...register('formFields.visaTypes_ID')} >
                                    <option></option>
                                    {listData.visaTypes.map((type, idx) => <option key={idx} value={type.visaTypes_ID}>{type.visaType}</option>)}
                                </select>
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label>Board Certified</label>
                                <select className="ant-input" {...register('formFields.boardCertified')} >
                                    <option></option>
                                    <option>Board Certified</option>
                                    <option>Board Eligible</option>
                                    <option>General Practitioner</option>
                                    <option>In Training Resident/Fellowship</option>
                                </select>
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label>Provider Type</label>
                                <select className="ant-input" {...register('formFields.providerType')} >
                                    <option></option>
                                    <option>APN</option>
                                    <option>CRNA</option>
                                    <option>D.O</option>
                                    <option>LPN</option>
                                    <option>M.D</option>
                                    <option>NP</option>
                                    <option>RN</option>
                                    <option>PA</option>
                                    <option>PhD</option>
                                    <option>Tech</option>
                                </select>
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label>Why Leaving?</label>
                                <textarea maxLength="2000" {...register('formFields.whyLeaving')} className="ant-input" />
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label className="d-block">Any problems with license or privledges?</label>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.problems')} value='yes' />
                                    <label> Yes</label>
                                </div>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.problems')} value='no' />
                                    <label> No</label>
                                </div>
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                {showProblems &&
                                    <>
                                        <label>Please provide details</label>
                                        <input maxLength="2000" type="text" {...register('formFields.problemsText')} className="ant-input" />
                                    </>
                                }
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label className="d-block">Any malpractice suits?</label>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.malpracticeSuits')} value='yes' />
                                    <label> Yes</label>
                                </div>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.malpracticeSuits')} value='no' />
                                    <label> No</label>
                                </div>
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                {showMalpractice &&
                                    <>
                                        <label>Please provide details</label>
                                        <input maxLength="2000" type="text" {...register('formFields.malpracticeSuitsText')} className="ant-input" />
                                    </>
                                }
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label className="d-block">Anything on their National Practitioner Database Report that would affect their placement?</label>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.affectPlacement')} value='yes' />
                                    <label> Yes</label>
                                </div>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.affectPlacement')} value='no' />
                                    <label> No</label>
                                </div>
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                {showAffectPlacement &&
                                    <>
                                        <label>Please provide details</label>
                                        <input maxLength="2000" type="text" {...register('formFields.affectPlacementText')} className="ant-input" />
                                    </>
                                }
                            </div>

                            <div className="form-group col-lg-8 col-12">
                                <label>Medical School Attended</label>
                                <input maxLength="100" type="text" {...register('formFields.medicalSchoolAttended')} className="ant-input" />
                            </div>

                            <div className="form-group col-lg-4 col-12">
                                <label>Graduation Date</label>
                                <Controller
                                    control={control}
                                    name="formFields.medicalSchoolGraduationDate"
                                    render={({ field: { onChange, onBlur, value, ref } }) =>
                                        <DatePicker
                                            selected={value}
                                            onChange={onChange}
                                            dateFormat="MM/yyyy"
                                            showMonthYearPicker
                                            className="ant-input"
                                        />
                                    }
                                />

                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label>Types of Practice</label>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Please select"
                                    value={selectedTypesOfPractice}
                                    onChange={updateTypesOfPractice}
                                    options={listData.typesOfPractice}
                                    optionFilterProp='label'
                                />
                            </div>
                        </div>
                    </Card>

                    <Card title={<span>Certifications <span className="float-end"><i className="far fa-plus-square icon-md hover candidate-text" id="add" onClick={addOrRemoveCertification}></i></span></span>} className="mb-3">
                        {selectedCertifications.map((cert, i) =>
                            <div className="row" key={i}>
                                <div className="col-12">
                                    <div className="float-end">
                                        <i id="remove" className="far fa-trash-alt icon-sm hover candidate-text" onClick={(e) => addOrRemoveCertification(e, i)} />
                                    </div>
                                </div>
                                <div className="form-group col-12">
                                    <label >Name</label>
                                    {cert.imported && <HighlightSpan />}
                                    <input maxLength="500" type="text" name="name" value={cert.name || ''} onChange={(e) => handleCertificationChange(e, i)} className="ant-input" />
                                </div>
                                <div className="form-group col-12">
                                    <label>Description</label>
                                    <textarea maxLength="2000" type="text" name="description" value={cert.description || ''} onChange={(e) => handleCertificationChange(e, i)} className="ant-input" />
                                </div>

                                {(selectedCertifications.length > 1 && i !== selectedCertifications.length - 1) && <div className="horizontal-candidates-divider" />}
                            </div>
                        )}
                    </Card>

                    <Card title='Location' className="mb-3">
                        <div className="row">
                            <div className="form-group col-12">
                                <label>Licensure (state(s) where this candidate can hold a license)</label>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Please select"
                                    value={selectedLicensureRecords}
                                    onChange={updateSelectedLicensures}
                                    options={modifiedStatesList}
                                    optionFilterProp='label'
                                />
                            </div>

                            <div className="horizontal-candidates-divider" />

                            <div className="form-group col-12">
                                <label className="d-block">Willing to Relocate?</label>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.willingToRelocate')} value='yes' />
                                    <label> Yes</label>
                                </div>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.willingToRelocate')} value='no' />
                                    <label> No</label>
                                </div>
                            </div>

                            {willingToRelocate &&
                                <div className="form-group col-12">
                                    <label>To Where?</label>
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Please select"
                                        value={selectedRelocationStates}
                                        onChange={updateRelocationStates}
                                        options={modifiedStatesList}
                                        optionFilterProp='label'
                                    />
                                </div>
                            }
                        </div>
                    </Card>

                    <Card title='Compensation' className="mb-3">
                        <div className="row">
                            <div className="form-group col-lg-6 col-12">
                                <label>Current Compensation</label>
                                <Controller
                                    control={control}
                                    name="formFields.currentCompensation"
                                    render={({ field: { onChange, onBlur, value, ref } }) =>
                                        <NumericFormat className="ant-input" thousandSeparator="," prefix={'$'} onChange={onChange} value={value} />
                                    }
                                />
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label className='d-block'>Compensation Type</label>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.currentCompensationType')} value='annually' />
                                    <label> Annually</label>
                                </div>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.currentCompensationType')} value='hourly' />
                                    <label> Hourly</label>
                                </div>
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label>Desired Compensation</label>
                                <Controller
                                    control={control}
                                    name="formFields.desiredCompensation"
                                    render={({ field: { onChange, onBlur, value, ref } }) =>
                                        <NumericFormat className="ant-input" thousandSeparator="," prefix={'$'} onChange={onChange} value={value} />
                                    }
                                />
                            </div>

                            <div className="form-group col-lg-6 col-12">
                                <label className='d-block'>Compensation Type</label>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.desiredCompensationType')} value='annually' />
                                    <label> Annually</label>
                                </div>
                                <div className="inline-custom-radio-container">
                                    <input type="radio" className="custom-radio" {...register('formFields.desiredCompensationType')} value='hourly' />
                                    <label> Hourly</label>
                                </div>
                            </div>

                            <div className="form-group col-12">
                                <label>Desired Position</label>
                                <input maxLength="300" className="ant-input" {...register('formFields.desiredPosition')} />
                            </div>

                            <div className="form-group col-12">
                                <label>Additional Comments</label>
                                <textarea maxLength="2000" className="ant-input" {...register('formFields.compensationComments')} />
                            </div>
                        </div>
                    </Card>

                    <Card title={<span>Education History <span className="float-end"><i className="far fa-plus-square icon-md hover candidate-text" id="add" onClick={addOrRemoveEducationHistory}></i></span></span>} className="mb-3">
                        {educationHistory.map((history, i) =>
                            <div className="row" key={i}>
                                <div className="col-12">
                                    {history.missingData &&
                                        <p className="text-danger float-end">** This entry could be missing data **</p>

                                    }
                                    <div className="float-end">
                                        <i id="remove" className="far fa-trash-alt icon-sm hover candidate-text" onClick={(e) => addOrRemoveEducationHistory(e, i)} />
                                    </div>
                                </div>
                                <div className="form-group col-12">
                                    <label>School</label>
                                    {history.imported && <HighlightSpan />}
                                    <input maxLength="100" type="text" name="schoolName" value={history.schoolName || ''} onChange={(e) => handleEducationHistoryChange(e, i)} className="ant-input" />
                                </div>
                                <div className="form-group col-12">
                                    <label>Degree Type</label>
                                    <input maxLength="50" type="text" name="degree" value={history.degree || ''} onChange={(e) => handleEducationHistoryChange(e, i)} className={"ant-input " + ((!history.degree && history.missingData) && 'border-danger')} />
                                </div>

                                <div className="form-group col-lg-6 col-12">
                                    <label>Residency Type</label>
                                    <input maxLength="250" type="text" name="residencyType" value={history.residencyType || ''} onChange={(e) => handleEducationHistoryChange(e, i)} className="ant-input" />
                                </div>

                                <div className="form-group col-lg-6 col-12">
                                    <label>Fellowship Type</label>
                                    <input maxLength="250" type="text" name="fellowshipType" value={history.fellowshipType || ''} onChange={(e) => handleEducationHistoryChange(e, i)} className="ant-input" />
                                </div>

                                {history.isCurrent ?
                                    <div className="form-group col-lg-4 col-12">
                                        <label>Degree Date</label>
                                        <input className="ant-input" value="Degree in Progress" readOnly />
                                    </div>
                                    :
                                    <div className="form-group col-lg-4 col-12">
                                        <label>Degree Date</label>
                                        <DatePicker
                                            selected={history.schoolDate}
                                            onChange={(date, dateName, index) => handleEducationHistoryDateChange(date, "schoolDate", i)}
                                            dateFormat="MM/yyyy"
                                            showMonthYearPicker
                                            className="ant-input"
                                        />
                                    </div>
                                }

                                <div className="form-group col-lg-4 col-12">
                                    <label className="d-block">In Progress?</label>
                                    <input type="checkbox" className="custom-checkbox" id="isCurrent" name="isCurrent" value={history.isCurrent} onChange={(e) => handleEducationHistoryChange(e, i)} checked={history.isCurrent} />
                                </div>


                                <div className="form-group col-12">
                                    <label className="form-label">Description</label>
                                    <textarea maxLength="250" type="text" name="description" value={history.description || ''} onChange={(e) => handleEducationHistoryChange(e, i)} className="ant-input" />
                                </div>
                                {(educationHistory.length > 1 && i !== educationHistory.length - 1) && <div className="horizontal-candidates-divider" />}
                            </div>
                        )}
                    </Card>

                    <Card title={<span>Work History <span className="float-end"><i className="far fa-plus-square icon-md hover candidate-text" id="add" onClick={addOrRemoveWorkHistory}></i></span></span>} className="mb-3">
                        {workHistory.map((history, i) =>
                            <div className="row" key={i}>
                                <div className="col-12">
                                    {history.missingData &&
                                        <p className="text-danger float-end">** This entry could be missing data **</p>

                                    }
                                    <div className="float-end">
                                        <i id="remove" className="far fa-trash-alt icon-sm hover candidate-text" onClick={(e) => addOrRemoveWorkHistory(e, i)} />
                                    </div>
                                </div>
                                <div className="form-group col-12">
                                    <label>Title</label>
                                    {history.imported && <HighlightSpan />}
                                    <input maxLength="100" type="text" name="title" value={history.title || ''} onChange={(e) => handleWorkHistoryChange(e, i)} className="ant-input" />
                                </div>
                                <div className="form-group col-12">
                                    <label>Company</label>
                                    <input maxLength="100" type="text" name="company" value={history.company || ''} onChange={(e) => handleWorkHistoryChange(e, i)} className={"ant-input " + ((!history.company && history.missingData) && 'border-danger')} />
                                </div>

                                <div className="form-group col-lg-4">
                                    <label>Start Date</label>
                                    <DatePicker
                                        selected={history.startDate}
                                        onChange={(date, dateName, index) => handleWorkHistoryDateChange(date, "startDate", i)}
                                        dateFormat="MM/yyyy"
                                        showMonthYearPicker
                                        className="ant-input"
                                    />
                                </div>
                                {history.isCurrent ?
                                    <div className="form-group col-lg-4 col-12">
                                        <label className="form-label">End Date</label>
                                        <input className="ant-input" value="Current Work" readOnly />
                                    </div>
                                    :
                                    <div className="form-group col-lg-4 col-12">
                                        <label>End Date</label>
                                        <DatePicker
                                            selected={history.endDate}
                                            onChange={(date, dateName, index) => handleWorkHistoryDateChange(date, "endDate", i)}
                                            dateFormat="MM/yyyy"
                                            showMonthYearPicker
                                            className="ant-input"
                                        />
                                    </div>
                                }
                                <div className="form-group col-lg-4 col-12">
                                    <label className="d-block">Current Work?</label>
                                    <input type="checkbox" className="custom-checkbox" id="isCurrent" name="isCurrent" value={history.isCurrent} onChange={(e) => handleWorkHistoryChange(e, i)} checked={history.isCurrent} />
                                </div>

                                <div className="form-group col-lg-4 col-12">
                                    <label>Work Type</label>
                                    <input maxLength="50" type="text" name="workType" value={history.workType || ''} onChange={(e) => handleWorkHistoryChange(e, i)} className="ant-input" />
                                </div>

                                <div className="form-group col-12 col-12">
                                    <label>Description</label>
                                    <textarea maxLength="2000" type="text" name="description" value={history.description || ''} onChange={(e) => handleWorkHistoryChange(e, i)} className="ant-input" />
                                </div>
                                {(workHistory.length > 1 && i !== workHistory.length - 1) && <div className="horizontal-candidates-divider" />}
                            </div>
                        )}
                    </Card>

                    <Card title={<span>References <span className="float-end"><i className="far fa-plus-square icon-md hover candidate-text" id="add" onClick={addOrRemoveReference}></i></span></span>} className="mb-3">
                        {references.map((ref, i) =>
                            <div className="row" key={i}>
                                <div className="col-12">
                                    <div className="float-end">
                                        <i id="remove" className="far fa-trash-alt icon-sm hover candidate-text" onClick={(e) => addOrRemoveReference(e, i)} />
                                    </div>
                                </div>
                                <div className="form-group col-12">
                                    <label>First Name</label>
                                    <input maxLength="100" type="text" name="firstName" value={ref.firstName || ''} onChange={(e) => handleReferenceChange(e, i)} className="ant-input" />
                                </div>

                                <div className="form-group col-12">
                                    <label>Last Name</label>
                                    <input maxLength="100" type="text" name="lastName" value={ref.lastName || ''} onChange={(e) => handleReferenceChange(e, i)} className="ant-input" />
                                </div>

                                <div className="form-group col-12">
                                    <label>Affix</label>
                                    <input maxLength="20" type="text" name="affix" value={ref.affix || ''} onChange={(e) => handleReferenceChange(e, i)} className="ant-input" />
                                </div>

                                <div className="form-group col-12">
                                    <label>Phone</label>
                                    <PatternFormat className="ant-input" name="phone" value={ref.phone || ''} onChange={(e) => handleReferenceChange(e, i)} format="###-###-####" />
                                </div>

                                {(references.length > 1 && i !== references.length - 1) && <div className="horizontal-candidates-divider" />}
                            </div>
                        )}
                    </Card>

                    <Card title='Tags' className="mb-3">
                        <div className="row">
                            <div className="form-group col-12">
                                <label>Tags</label>
                                <Select
                                    id="tags"
                                    mode="multiple"
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Please select"
                                    value={selectedTags}
                                    onChange={updateTags}
                                    options={listData.orgTags}
                                    optionFilterProp='label'
                                    onInputKeyDown={createTag}
                                    notFoundContent='Press enter to create a new item'
                                />
                            </div>
                        </div>
                    </Card>

                    <Card title='Social Media'>
                        <div className="row">
                            <div className="form-group col-12">
                                <label>Facebook</label>
                                <Controller
                                    control={control}
                                    name="formFields.facebook"
                                    render={({ field: { onChange, onBlur, value, ref } }) =>
                                        <Input maxLength="29" addonBefore={facebookPrefix} onChange={onChange} value={value} />
                                    }
                                />

                            </div>
                            <div className="form-group col-12">
                                <label>LinkedIn</label>
                                <Controller
                                    name="formFields.linkedIn"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) =>
                                        <Input maxLength="22" addonBefore={linkedInPrefix} onChange={onChange} value={value} />
                                    }
                                />
                            </div>
                            <div className="form-group col-12">
                                <label>Twitter</label>
                                <Controller
                                    name="formFields.twitter"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) =>
                                        <Input maxLength="26" addonBefore={twitterPrefix} onChange={onChange} value={value} />
                                    }
                                />
                            </div>
                            <div className="form-group col-12">
                                <label>URL</label>
                                <Controller
                                    name="formFields.url"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) =>
                                        <Input maxLength="40" addonBefore={urlPrefix} onChange={onChange} value={value} />
                                    }
                                />
                            </div>
                        </div>
                    </Card>

                    <Card title='Comments'>
                        <div className="row">
                            <div className="form-group col-12">
                                <Editor
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="custom-editor"
                                    onEditorStateChange={onEditorStateChange}
                                    toolbar={{
                                        options: ['inline', 'list', 'textAlign'],
                                        inline: {
                                            options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                                            bold: { className: 'bordered-option-classname' },
                                            italic: { className: 'bordered-option-classname' },
                                            underline: { className: 'bordered-option-classname' },
                                            strikethrough: { className: 'bordered-option-classname' },
                                            code: { className: 'bordered-option-classname' },
                                        },
                                        list: {
                                            unordered: { className: 'demo-option-custom' },
                                            ordered: { className: 'demo-option-custom' },
                                            indent: { className: 'demo-option-custom' },
                                            outdent: { className: 'demo-option-custom' },
                                        },
                                        textAlign: {
                                            left: { className: 'demo-option-custom' },
                                            center: { className: 'demo-option-custom' },
                                            right: { className: 'demo-option-custom' },
                                            justify: { className: 'demo-option-custom' },
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </Drawer>
    );
}