import useSWR from "swr"
import { fetcher } from "./fetcher"

export function useCandidates(org_ID, users_ID, placementStatus, pageSize, offset, searchValue, searchByResume, filterString, sortBy, sortDirection, showArchived, candidateOwnership) {
    const { data, error, isLoading, isMutating } = useSWR(() => org_ID ? `/api/GetCandidatesWithPlacementsByUser?org_ID=${org_ID}&users_ID=${users_ID}&placementStatus=${placementStatus}&pageSize=${pageSize}&offset=${offset}&searchValue=${searchValue}&searchByResume=${searchByResume}&sortBy=${sortBy}&sortDirection=${sortDirection}&showArchived=${showArchived}${filterString ? filterString : ''}&candidateOwnership=${candidateOwnership}` : null, fetcher)
   
    return {
      data,
      isLoading,
      isError: error,
      isMutating
    }
  }