import React, { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilState, useResetRecoilState } from 'recoil';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { activeCandidateTab, activeUserCandidatesState, candidateFilterFields, candidateTableParams, flagCandidatesForNCHCR, unFlagCandidatesForNCHCR, userCandidates } from '../../services/CandidateService';
import { Table, Tabs, Tag, Input, Empty, Popover, Badge, Card, Select, Divider, Collapse, Modal } from 'antd';
import Moment from 'moment';
import statesList from '../../helpers/StatesList';
import { useNavigate } from "react-router-dom";
import { AddCandidate } from './AddCandidate';
import { useMediaQuery } from 'react-responsive';
import Globals from '../../config/globals';
import SkeletonTable from '../../helpers/SkeletonTable';
import { jobTypes, orgInfoLoading, parentSpecialties, subSpecialties, visaTypes } from '../../services/OrganizationsService';
import { activeUserPermissions, userInfo } from '../../services/UsersService';
import { toast } from '@rickylandino/react-messages';
import { MatchPane } from '../Placements/MatchPane';
import CandidatePlacementWorkflow from './CandidatePlacementWorkflow';
import { useCandidates } from '../../swr/candidates';
import { useForm } from "react-hook-form";

export function CandidatesHome(props) {
    const { Panel } = Collapse;

    const navigate = useNavigate();
    const { register, watch, setValue } = useForm();

    const isDesktop = useMediaQuery({ minWidth: Globals.xlScreen });

    const [loading, setLoading] = useState(false);

    const [uInfo, setUInfo] = useState({});
    const uPermissions = useRecoilValue(activeUserPermissions);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [activeTab, setActiveTab] = useRecoilState(activeCandidateTab);
    const [candidateOwnership, setCandidateOwnership] = useState('user');

    const [filterCollapsed, setFilterCollapsed] = useState(true);

    const [showSelectNchcr, setShowSelectNchcr] = useState(false);

    const [candidateLists, setCandidateLists] = useState({
        candidateList: [],
        allUserCandidates: [],
        filterUserCandidates: [],
        activeUserCandidates: [],
        allCandidates: [],
        nchcrCandidates: [],
        loaded: false
    });

    const [activeUserCandidates, setActiveUserCandidates] = useRecoilState(activeUserCandidatesState)

    const [filterFields, setFilterFields] = useRecoilState(candidateFilterFields);
    const resetFilterFields = useResetRecoilState(candidateFilterFields);

    const [showAddCandidate, setShowAddCandidate] = useState(false);
    const [workflowPane, setWorkflowPane] = useState({
        show: false,
        candidate: null
    });

    const [modifiedStatesList, setModifiedStatesList] = useState([]);
    const [modifiedVisaTypes, setModifiedVisaTypes] = useState([]);
    const [modifiedSpecialties, setModifiedSpecialties] = useState([]);

    const [allSubSpecialties, setAllSubSpecialties] = useState({});
    const [modifiedSubSpecialties, setModifiedSubSpecialties] = useState([]);

    const [workflowCandidates, setWorkflowCandidates] = useState({
        activeWorkflow: [],
        inactiveWorkflow: [],
        completedWorkflow: [],
        terminatedWorkflow: []
    });

    const [modifiedJobTypes, setModifiedJobTypes] = useState([]);

    const [status, setStatus] = useState('Active');

    const [tableParams, setTableParams] = useRecoilState(candidateTableParams);

    const [searchValue, setSearchValue] = useState('');
    const [searchByResume, setSearchByResume] = useState(false);

    const [filterString, setFilterString] = useState('');

    const [sortStuff, setSortStuff] = useState({
        sortBy: "",
        sortDirection: null
    });

    const [showArchived, setShowArchived] = useState(false);

    const { data, isLoading, error } = useCandidates(
        uInfo.organizations_ID,
        uInfo.users_ID,
        status,
        tableParams.pagination.pageSize,
        tableParams.pagination.current - 1,
        searchValue,
        searchByResume,
        filterString,
        sortStuff.sortBy,
        sortStuff.sortDirection,
        showArchived,
        candidateOwnership
    );

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if(name === 'candidateOwnership') {
                setCandidateOwnership(value.candidateOwnership);
            }
        });

        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if (!data) return;
        setActiveUserCandidates(data);
    }, [data]);

    useEffect(() => {
        var alive = true;

        combineLatest(
            userCandidates,
            orgInfoLoading,
            userInfo,
            visaTypes,
            parentSpecialties,
            subSpecialties,
            jobTypes
        ).pipe(takeWhile(() => alive)).subscribe(([data, orgInfoLoading, userInfo, vt, ps, ss, jt]) => {
            setLoading(orgInfoLoading);

            var mVisas = modifyVisaTypes(vt);
            setModifiedVisaTypes(mVisas);

            var mSpecs = modifiySpecialties(ps);
            setModifiedSpecialties(mSpecs);

            var mJobTypes = modifyJobTypes(jt);
            setModifiedJobTypes(mJobTypes);

            setAllSubSpecialties(ss);

            if (userInfo.users_ID) {
                setUInfo(userInfo);
            }

            if (filterFields.specialty) {
                let mSubSpecs = modifiySpecialties(ss[filterFields.specialty]);
                setModifiedSubSpecialties(mSubSpecs);
            }
        });

        var modifiedStates = modifyStates();
        setModifiedStatesList(modifiedStates);




        return () => { alive = false; }
    }, []);

    useEffect(() => {
        let ff = '';

        for (const property in filterFields) {
            if (filterFields[property]) {
                if ((Array.isArray(filterFields[property]) && filterFields[property].length > 0)) {
                    ff += `&filters=${property}:in:${filterFields[property]}`;
                } else if (!Array.isArray(filterFields[property])) {
                    if (property === 'specialty') {
                        ff += `&filters=${property}:eq:${filterFields[property]}`;
                    } else {
                        ff += `&filters=${property}:cn:${filterFields[property]}`;
                    }

                }
            }
        }

        setFilterString(ff);

    }, [filterFields]);

    useEffect(() => {
        if (!loading && activeUserCandidates) {
            let cands = activeUserCandidates?.userCandidates?.map(item => ({ ...item, matchedOn: null }));

            let candsToSet = cands ? [...cands] : [];

            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: candsToSet.length > 0 ? candsToSet[0].totalCount : 0
                }
            });

            setCandidateLists({
                ...candidateLists,
                allUserCandidates: cands,
                filterUserCandidates: candsToSet,
                candidateList: candsToSet,
                loaded: true
            });

            //setCandidatesToFilterAgainst(candsToSet);
        }

    }, [activeUserCandidates, activeTab, loading]);

    useEffect(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                current: 1
            }
        });
    }, [showArchived]);

    function modifyStates(states = null) {
        let newList = states ? states : statesList.filter(obj => { return obj.country_code === 'US' });

        newList = newList.map(item => ({
            label: item.name,
            value: item.state_code,
            country: item.country_code
        }));

        return newList;
    }

    function modifyVisaTypes(visaTypes) {
        let newList = visaTypes.map(item => ({
            label: item.visaType,
            value: item.visaTypes_ID
        }));

        return newList;
    }

    function modifiySpecialties(specialtiesList) {
        let newList = specialtiesList.map(item => ({
            label: item.specialty,
            value: item.specialties_ID
        }));

        return newList;
    }

    function modifyJobTypes(jobTypes) {
        let newList = jobTypes.map(item => ({
            label: item.jobType,
            value: item.jobTypes_ID
        }));

        return newList;
    }

    function navigateToCandidateDashboard(row) {
        navigate(`/candidate-dashboard/${row.candidates_ID}`,
            {
                replace: true,
                state: { from: 'myCandidates' }
            });
    }

    function pushToNchcr() {
        flagCandidatesForNCHCR(selectedRowKeys, "Active").then(data => {
            if (data) {
                setSelectedRowKeys([]);
                setShowSelectNchcr(false);
                toast.success("Candidates have been flagged for NCHCR");

                userCandidates.next(data);
                setActiveUserCandidates(data);
            } else {
                toast.error("Something went wrong");
            }
        });
    }

    function removePushToNchcr() {
        unFlagCandidatesForNCHCR(selectedRowKeys, "Active").then(data => {
            if (data) {
                setSelectedRowKeys([]);
                setShowSelectNchcr(false);
                toast.success("Candidates have been un-flagged for NCHCR");

                userCandidates.next(data);
                setActiveUserCandidates(data);
                setCandidateOwnership('user');
                setValue('candidateOwnership', 'user');
            } else {
                toast.error("Something went wrong");
            }
        });
    }

    function showWorkflowPane(candidate) {
        setWorkflowPane({
            show: true,
            candidate
        });
    }

    function matchFormatter(cell, row) {
        return (
            <button id="matchButton" className="ant-btn ant-btn-match btn-sm" onClick={() => showWorkflowPane(row)}> Match Jobs</ button>
        );
    }

    function getSortDirection(column) {
        var direction = null;
        var sortBy = "";

        if(sortStuff.sortDirection === null || sortStuff.sortBy !== column.key) {
            sortBy = column.key;
            direction = 'asc';
        } else if (sortStuff.sortDirection === 'asc') {
            sortBy = column.key;
            direction = 'desc';
        }

        return { sortBy, direction };
    }

    function iconFormatter(cell, row) {
        return (
            <>
                {row.confidential &&
                    <Popover overlayInnerStyle={{ width: '200px' }} placement="right" content={<p>You have configured this candidate to be confidential, meaning it can only be seen by you.</p>}>
                        <i className="fa-solid fa-key"></i>
                    </Popover>
                }
                {row.users_ID !== uInfo.users_ID &&
                    <Popover className="m-1" overlayInnerStyle={{ width: '200px' }} placement="right" content={<p>This candidate is owned by {row.recruiter}</p>}>
                        <i className="candidate-text fa-solid fa-users-between-lines"></i>
                    </Popover>
                }
                {(row.pushToNchcr && uInfo.nchcrMember) &&
                    <Popover className="ms-1" overlayInnerStyle={{ width: '200px' }} placement="right" content={<p>NCHCR Candidate</p>}>
                        <i className="fa-solid fa-n" style={{color: '#537ab2'}}></i>
                    </Popover>
                }
                
            </>
        );
    }

    var candidateColumns = [
        {
            title: '',
            dataIndex: 'confidential',
            key: 'confidential',
            render: iconFormatter,
            responsive: ['md'],
            width: 50
        },
        {
            title: 'In-House ID',
            dataIndex: 'inHouseID',
            key: 'inHouseID',
            responsive: ['lg'],
        },
        {
            title: 'Last Name',
            dataIndex: 'candidateLastName',
            key: 'lastName',
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'lastName' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'lastName' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => { 
                        var { direction, sortBy } = getSortDirection(column); 
                        setSortStuff({sortBy, sortDirection: direction }) 
                    }
                };
            },
            render: (cell, row) => {
                return (
                    <>
                        {row.matchedOn &&
                            <Popover placement="right" content={<p>Your search matched on the following: <br /> <strong>{row.matchedOn}</strong></p>}>
                                <i className="fa-regular fa-circle-question candidate-text hover"></i>
                            </Popover>
                        }
                        {!cell &&
                            <Popover placement="right" content={<p>System generated candidate based on a text message received OR imported candidate with no name.</p>}>
                                <i className="fa-regular fa-circle-question candidate-text hover"></i>
                            </Popover>
                        }
                        &nbsp;
                        {cell ? cell : 'Empty'}
                    </>
                );
            }
        },
        {
            title: 'First Name',
            dataIndex: 'candidateFirstName',
            key: 'firstName',
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'firstName' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'firstName' ? 'descend' : null,
            onHeaderCell: (column) => {

                return {
                    onClick: () => { 
                        var { direction, sortBy } = getSortDirection(column);
                        setSortStuff({sortBy, sortDirection: direction }) 
                    }
                };
            },
            render: (cell, row) => {
                if (cell) {
                    return cell;
                } else if (row.candidateNumber) {
                    return row.candidateNumber;
                }
            }
        },
        {
            title: 'Specialty',
            dataIndex: 'parentSpecialty',
            key: 'parentSpecialty',
            ellipsis: true,
            responsive: ['lg'],
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'parentSpecialty' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'parentSpecialty' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => { 
                        var { direction, sortBy } = getSortDirection(column); 
                        setSortStuff({sortBy, sortDirection: direction }) 
                    }
                };
            },
        },
        {
            title: 'Sub Specialty',
            dataIndex: 'subSpecialty',
            key: 'subSpecialty',
            ellipsis: true,
            responsive: ['lg'],
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'subSpecialty' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'subSpecialty' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => { 
                        var { direction, sortBy } = getSortDirection(column); 
                        setSortStuff({sortBy, sortDirection: direction }) 
                    }
                };
            },
        },
        {
            title: 'Current Licenses',
            dataIndex: 'licensure',
            key: 'licensure',
            ellipsis: true,
            responsive: ['xxl'],
            render: (cell) => {
                const tags = cell?.split('|');

                return (
                    <>
                        {tags?.map((tag) => {
                            let color = 'cyan';
                            if (tag) {
                                return (
                                    <Tag color={color} key={tag}>
                                        {tag.toUpperCase()}
                                    </Tag>
                                );
                            }
                        })}
                    </>
                )
            }
        },
        {
            title: 'Preferred State(s)',
            dataIndex: 'preferredStatesToWork',
            key: 'preferredStatesToWork',
            ellipsis: true,
            responsive: ['xxl'],
            render: (cell) => {
                const tags = cell?.split('|');

                return (
                    <>
                        {tags?.map((tag) => {
                            let color = 'red';
                            if (tag) {
                                return (
                                    <Tag color={color} key={tag}>
                                        {tag.toUpperCase()}
                                    </Tag>
                                );
                            }
                        })}
                    </>
                )
            }
        },
        {
            title: 'State Living In',
            dataIndex: 'state',
            key: 'state',
            ellipsis: true,
            responsive: ['md']
        },
        {
            title: 'Recruiter',
            dataIndex: 'recruiter',
            key: 'recruiter',
            sorter: true,
            sortOrder: sortStuff.sortDirection === 'asc' && sortStuff.sortBy === 'recruiter' ? 'ascend' : sortStuff.sortDirection === 'desc' && sortStuff.sortBy === 'recruiter' ? 'descend' : null,
            onHeaderCell: (column) => {
                return {
                    onClick: () => { 
                        var { direction, sortBy } = getSortDirection(column); 
                        setSortStuff({sortBy, sortDirection: direction }) 
                    }
                };
            },
        },
        {
            title: 'Date Added',
            dataIndex: 'candidateDateAdded',
            key: 'dateAdded',
            render: (cell) => Moment(new Date(cell)).format('L'),
            ellipsis: true,
            responsive: ['md']
        },
        {
            title: 'Match',
            render: matchFormatter
        }
    ];

    function handleSearch(value) {
        setSearchValue(value);
    }

    function handleFilter(value, property) {
        let ff = { ...filterFields };
        ff[property] = value || null;

        if (property === 'specialty' && value) {
            let mSubSpecs = modifiySpecialties(allSubSpecialties[value]);
            setModifiedSubSpecialties(mSubSpecs);
        }

        setFilterFields(ff);
    }

    function handleTabChange(activeKey) {
        setActiveTab(activeKey);
        setSearchValue('');
    }

    function showNchcrInstructions() {
        Modal.info({
            title: 'NCHCR Instructions',
            content: (
                <div>
                    <p>You are an NCHCR member and have the ability to sync Candidates in RecruiterBalm over to NCHCR. Here is how:</p>
                    <ol>
                        <li>Click the button "Select Candidates for NCHCR"</li>
                        <li>Click the checkbox next to the candidate(s) you would like to flag for NCHCR</li>
                        <li>Click the button in the top right corner above the table to flag those candidates for NCHCR</li>
                    </ol>
                    <Divider />
                    <p>To remove candidates, you can perform the same steps from the NCHCR Ownership view via Candidate Ownership dropdown.</p>
                </div>
            ),
            onOk() { },
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        
        setTableParams({
            pagination: {
                ...tableParams.pagination,
                current: pagination.current,
                pageSize: pagination.pageSize
            },
            filters,
            ...sorter,
        });
    };

    const dropdownRender = (record) => {
        const preferredStates = record?.candidates?.preferredStatesToWork?.split('|') || [];
        const licensure = record?.candidates?.licensure?.split('|') || [];

        return (
            <div className="ms-4">
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <label>Specialty:</label>
                        &nbsp;
                        {record.parentSpecialty}
                    </div>
                    <div className="col-lg-6 col-12">
                        <label>Sub Specialty:</label>
                        &nbsp;
                        {record.subSpecialty}
                    </div>
                </div>
                <div>
                    <label>Preferred State(s):</label>
                    &nbsp;
                    {preferredStates.length > 0 ?
                        <>
                            {preferredStates.map((tag) => {
                                let color = 'red';
                                if (tag) {
                                    return (
                                        <Tag color={color} key={tag}>
                                            {tag.toUpperCase()}
                                        </Tag>
                                    );
                                }
                            })}
                        </>
                        :
                        'N/A'
                    }
                </div>
                <div>
                    <label>Current Licenses:</label>
                    &nbsp;
                    {licensure.length > 0 ?
                        <>
                            {licensure.map((tag) => {
                                let color = 'cyan';
                                if (tag) {
                                    return (
                                        <Tag color={color} key={tag}>
                                            {tag.toUpperCase()}
                                        </Tag>
                                    );
                                }
                            })}
                        </>
                        :
                        'N/A'
                    }
                </div>
            </div>
        );
    }

    const columns = candidateColumns.filter(c => !c.hide);

    var legendIcon = candidateLists.filterUserCandidates.find(item => item.matchedOn) ?
        (
            <div className="row ms-4" style={{ width: '170px' }}>

                <Badge.Ribbon text={<span>Why am I seeing this? &nbsp;
                    <Popover overlayInnerStyle={{ width: '200px' }} placement="right" content={<p>Your filter matched on candidate information that is not displayed in the table below. Hover over me next to the candidate to see more information.</p>}>
                        <i className="fa-regular fa-circle-question hover"></i>
                    </Popover>
                </span>} color="firebrick" className="">
                    <Card size="small">
                    </Card>
                </Badge.Ribbon>
            </div>
        ) : null;

    const filterFieldsDisplay = () => {
        return (
            <>
                <Collapse defaultActiveKey={null} ghost onChange={() => setFilterCollapsed(!filterCollapsed)}>
                    <Panel header={<div><span className="a">{filterCollapsed ? "Expand" : "Collapse"}&nbsp;Filters</span>{!filterCollapsed && <span className="a ms-5" onClick={resetFilterFields}>Reset Filters</span>}</div>} key="1">
                        <Divider orientation="left">Filter By</Divider>
                        <div className="row">
                            <div className="col col-lg-3 col-12">
                                <label>In-House ID</label>
                                <input className="ant-input" value={filterFields.inHouseID} onChange={(e) => setFilterFields({ ...filterFields, inHouseID: e.target.value })} />
                            </div>

                            <div className="col col-lg-3 col-12">
                                <label>Preferred States</label>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    value={filterFields.preferredStatesToWork}
                                    placeholder="Please select"
                                    onChange={(value) => handleFilter(value, 'preferredStatesToWork')}
                                    options={modifiedStatesList}
                                    optionFilterProp='label'
                                />
                            </div>

                            <div className="col col-lg-3 col-12">
                                <label>Job Types</label>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    value={filterFields.jobTypes}
                                    placeholder="Please select"
                                    onChange={(value) => handleFilter(value, 'jobTypes')}
                                    options={modifiedJobTypes}
                                    optionFilterProp='label'
                                />
                            </div>

                            <div className="col col-lg-3 col-12">
                                <label>Visa Types</label>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    value={filterFields.visaTypes_ID}
                                    placeholder="Please select"
                                    onChange={(value) => handleFilter(value, 'visaTypes_ID')}
                                    options={modifiedVisaTypes}
                                    optionFilterProp='label'
                                />
                            </div>

                            <div className="col col-lg-4 col-12">
                                <label>Licensure (state(s) where this candidate can hold a license)</label>
                                <Select
                                    mode="multiple"
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    value={filterFields.licensure}
                                    placeholder="Please select"
                                    onChange={(value) => handleFilter(value, 'licensure')}
                                    options={modifiedStatesList}
                                    optionFilterProp='label'
                                />
                            </div>

                            <div className="col col-lg-4 col-12">
                                <label>Specialty</label>
                                <Select
                                    allowClear
                                    style={{
                                        width: '100%',
                                    }}
                                    value={filterFields.specialty}
                                    showSearch
                                    placeholder="Please select"
                                    onChange={(value) => handleFilter(value, 'specialty')}
                                    filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
                                    options={modifiedSpecialties}
                                    optionFilterProp='label'
                                />
                            </div>
                            <div className="col col-lg-4 col-12">
                                <label>Sub Specialty</label>
                                {filterFields.specialty ?
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        style={{
                                            width: '100%',
                                        }}
                                        value={filterFields.subSpecialties}
                                        placeholder="Please select"
                                        onChange={(value) => handleFilter(value, 'subSpecialties')}
                                        options={modifiedSubSpecialties}
                                        optionFilterProp='label'
                                    />
                                    :
                                    <p><em>Please select a specialty first</em></p>
                                }
                            </div>
                        </div>
                    </Panel>
                </Collapse>
            </>
        );
    }

    const onSelectChange = (newSelectedRowKeys, selected, selectedRows) => {
        var newSelectedRowKeys = selectedRows.map(fc => fc.candidates_ID);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const onSelectAllChange = (selected, selectedRows, changeRows) => {
        var newSelectedRowKeys = candidateLists.filterUserCandidates.map(fc => fc.candidates_ID);
        setSelectedRowKeys(selected ? newSelectedRowKeys : []);
    }

    const rowSelection = {
        selectedRowKeys,
        onSelect: onSelectChange,
        onSelectAll: onSelectAllChange
    };
    
    const selectedLength = selectedRowKeys.length;
    const pushDisabled = selectedRowKeys.length > 0;

    var tabs = [
        {
            label: <div className="candidate-text">Candidate Workflow Summary</div>,
            key: 'candidateWorkflowSummary',
            children: (
                <CandidatePlacementWorkflow workflowCandidates={workflowCandidates} />
            )
        },
        {
            label: <div className="candidate-text">Candidates</div>,
            key: 'myCandidates',
            children: (
                <>
                    {loading ?
                        <SkeletonTable columns={columns} rowCount={5} />
                        :
                        <>
                            <div className="row">
                                {uPermissions?.users_ID && uPermissions?.readCandidates === 'company' &&
                                    <div className="col-xl-2 col-lg-3 col-12">
                                        <label>Filter by Candidate Ownership</label>
                                        <select className="ant-input" {...register('candidateOwnership')}>
                                            <option value="user">My Candidates</option>
                                            <option value="org">Organizational Candidates</option>
                                            {uInfo.nchcrMember && <option value="nchcr">Candidates Flagged For NCHCR</option>}
                                        </select>
                                    </div>
                                }
                                <div className="form-group col-xl-2 col-lg-3 col-6">
                                    <label className="ms-1 d-block">Show Archived Candidates</label>
                                    <input type="checkbox" className="custom-checkbox" checked={showArchived} onChange={() => setShowArchived(!showArchived)} />
                                </div>
                                {tableParams.pagination.total >= 0 &&
                                    <div className="form-group col-lg-2 col-6 mx-3 align-items-center">
                                        Total:&nbsp;<strong>{tableParams.pagination.total} Candidate(s)</strong>
                                    </div>
                                }
                            </div>

                            
                            
                            {filterFieldsDisplay()}

                            <Divider orientation="left">Or Search By</Divider>

                            <Input.Search
                                placeholder="Search for keywords..."
                                onSearch={handleSearch}
                                onChange={(e) => { handleSearch(e.target.value) }}
                                value={searchValue}
                            />

                            <div className="d-flex">
                                <div className="form-group mx-1">
                                    <input type="checkbox" className="custom-checkbox" checked={searchByResume} onChange={() => setSearchByResume(!searchByResume)} />
                                    <label className="ms-1 pt-3">Search across Candidate Resumes <br /><small><em>This could impact performance</em></small></label>
                                </div>
                            </div>
                            <div className="">{legendIcon}</div>
                            <Divider></Divider>

                            {candidateLists.filterUserCandidates?.length > 0 ?
                                <>
                                    {uInfo.nchcrMember &&
                                        <>
                                            {candidateOwnership !== "nchcr" ?
                                                <>
                                                    {!showSelectNchcr ?
                                                        <div className="d-flex justify-content-start">
                                                            <button className="ant-btn ant-btn-primary mb-3" onClick={() => setShowSelectNchcr(true)}>
                                                                Select Candidates for NCHCR
                                                            </button>
                                                            <i title="Ways to send email" className="fa-regular fa-circle-question hover ms-3" onClick={showNchcrInstructions}></i>
                                                        </div>
                                                        :
                                                        <div className="d-flex justify-content-between">
                                                            <button className="ant-btn ant-btn-outline mb-3" onClick={() => { setShowSelectNchcr(false); setSelectedRowKeys([]); }}>
                                                                Cancel Selection
                                                            </button>

                                                            {showSelectNchcr &&
                                                                <button className="ant-btn ant-btn-primary float-end mb-3" onClick={pushToNchcr} disabled={!pushDisabled}>
                                                                    Flag {selectedLength} Candidates for NCHCR
                                                                </button>
                                                            }
                                                        </div>
                                                    }
                                                </>
                                                :
                                                <>
                                                    {!showSelectNchcr ?
                                                        <div className="d-flex justify-content-start">
                                                            <button className="ant-btn ant-btn-primary mb-3" onClick={() => setShowSelectNchcr(true)}>
                                                                Remove Candidates for NCHCR
                                                            </button>
                                                        </div>
                                                        :
                                                        <div className="d-flex justify-content-between">
                                                            <button className="ant-btn ant-btn-outline mb-3" onClick={() => { setShowSelectNchcr(false); setSelectedRowKeys([]); }}>
                                                                Cancel Selection
                                                            </button>

                                                            {showSelectNchcr &&
                                                                <button className="ant-btn ant-btn-primary float-end mb-3" onClick={removePushToNchcr} disabled={!pushDisabled}>
                                                                    Remove {selectedLength} Candidates Flagged for NCHCR
                                                                </button>
                                                            }
                                                        </div>
                                                    }
                                                </>
                                            }
                                            
                                        </>
                                    }
                                    
                                    <Table
                                        rowKey={(record) => record.candidates_ID}
                                        dataSource={candidateLists.filterUserCandidates}
                                        rowSelection={showSelectNchcr ? rowSelection : null}
                                        columns={columns}
                                        size='small'
                                        onRow={(record, rowIndex) => {
                                            return {
                                                onClick: event => { (event.target.id !== 'matchButton' && event.target.id !== 'myCheckbox' && !showSelectNchcr) ? navigateToCandidateDashboard(record) : console.log("Selected") }, // click row
                                                onDoubleClick: event => { navigateToCandidateDashboard(record) }, // double click row
                                                onContextMenu: event => { }, // right button click row
                                                onMouseEnter: event => { }, // mouse enter row
                                                onMouseLeave: event => { }, // mouse leave row
                                            };
                                        }}
                                        expandable={!isDesktop && {
                                            expandedRowRender: (record) => dropdownRender(record),
                                            rowExpandable: (record) => record.candidates_ID !== 'Not Expandable',
                                        }}

                                        onChange={handleTableChange}
                                        pagination={tableParams.pagination}
                                    />
                                </>
                                :
                                <Empty description={<p>You currently have no candidates - to begin adding a candidate, click the <i className="far fa-plus-square" /> icon on the top banner, and select 'Add a Candidate'.</p>} />
                            }
                        </>
                    }
                </>
            ),
        }
        // ,
        // {
        //     label: <div className="candidate-text">Organizational Candidates</div>,
        //     key: 'companyCandidates',
        //     children: (
        //         <>
        //             {loading ?
        //                 <SkeletonTable columns={columns} rowCount={5} />
        //                 :
        //                 <>
        //                     <div className="d-flex">
        //                         <div className="form-group mx-1 d-flex">
        //                             <input type="checkbox" className="custom-checkbox" checked={showArchived} onChange={() => setShowArchived(!showArchived)} />
        //                             <label className="ms-1 align-self-center">Show Archived Candidates</label>
        //                         </div>

        //                         {tableParams.pagination.total >= 0 &&
        //                             <div className="form-group mx-3 ps-4 d-flex align-items-center">
        //                                 Total:&nbsp;<strong>{tableParams.pagination.total} Candidate(s)</strong>
        //                             </div>
        //                         }
        //                     </div>
                            
        //                     <span className="a float-end" onClick={resetFilterFields}>Reset Filters</span>
                            
        //                     {filterFieldsDisplay()}

        //                     <Divider orientation="left">Or Search By</Divider>

        //                     <Input.Search
        //                         placeholder="Search for keywords..."
        //                         onSearch={handleSearch}
        //                         onChange={(e) => { handleSearch(e.target.value) }}
        //                         value={searchValue}
        //                     />

        //                     <div className="">{legendIcon}</div>
        //                     <Divider></Divider>
        //                     {candidateLists.filterUserCandidates?.length > 0 ?
        //                         <>
        //                             {uInfo.nchcrMember &&
        //                                 <div className="d-flex justify-content-end">
        //                                     <button className="ant-btn ant-btn-primary float-end mb-3" onClick={pushToNchcr} disabled={!pushDisabled}>
        //                                         Flag {selectedLength} Candidates for NCHCR
        //                                     </button>
        //                                 </div>
        //                             }
        //                             <Table
        //                                 rowKey={(record) => record.candidates_ID}
        //                                 dataSource={candidateLists.filterUserCandidates}
        //                                 rowSelection={uInfo?.nchcrMember ? rowSelection : null}
        //                                 columns={columns.filter(c => c.key !== 'confidential')}
        //                                 size='small'
        //                                 onRow={(record, rowIndex) => {
        //                                     return {
        //                                         onClick: event => { (event.target.id !== 'matchButton' && event.target.id !== 'myCheckbox') ? navigateToCandidateDashboard(record) : console.log("Selected") }, // click row
        //                                         onDoubleClick: event => { }, // double click row
        //                                         onContextMenu: event => { }, // right button click row
        //                                         onMouseEnter: event => { }, // mouse enter row
        //                                         onMouseLeave: event => { }, // mouse leave row
        //                                     };
        //                                 }}
        //                                 expandable={!isDesktop && {
        //                                     expandedRowRender: (record) => dropdownRender(record),
        //                                     rowExpandable: (record) => record.candidates_ID !== 'Not Expandable',
        //                                 }}
        //                                 onChange={handleTableChange}
        //                                 pagination={tableParams.pagination}
        //                             />
        //                         </>
        //                         :
        //                         <Empty description={<p>You currently have no candidates - to begin adding a candidate, click the <i className="far fa-plus-square" /> icon on the top banner, and select 'Add a Candidate'.</p>} />
        //                     }
        //                 </>
        //             }
        //         </>
        //     ),
        // }
        // ,
        // {
        //     label: <div className="candidate-text">NCHCR Candidates</div>,
        //     key: 'nchcrCandidates',
        //     children: (
        //         <>
        //             {loading ?
        //                 <SkeletonTable columns={columns} rowCount={5} />
        //                 :
        //                 <>
        //                     <div className="d-flex">
        //                         <div className="form-group mx-1 d-flex">
        //                             <input type="checkbox" className="custom-checkbox" checked={showArchived} onChange={() => setShowArchived(!showArchived)} />
        //                             <label className="ms-1 align-self-center">Show Archived Candidates</label>
        //                         </div>

        //                         {tableParams.pagination.total >= 0 &&
        //                             <div className="form-group mx-3 ps-4 d-flex align-items-center">
        //                                 Total:&nbsp;<strong>{tableParams.pagination.total} Candidate(s)</strong>
        //                             </div>
        //                         }
        //                     </div>

        //                     <span className="a float-end" onClick={resetFilterFields}>Reset Filters</span>
                            
        //                     {filterFieldsDisplay()}

        //                     <Divider orientation="left">Or Search By</Divider>

        //                     <Input.Search
        //                         placeholder="Search for keywords..."
        //                         onSearch={handleSearch}
        //                         onChange={(e) => { handleSearch(e.target.value) }}
        //                         value={searchValue}
        //                     />

        //                     <div className="">{legendIcon}</div>
        //                     <Divider></Divider>
        //                     {candidateLists.filterUserCandidates?.length > 0 ?
        //                         <>
        //                             <div className="d-flex justify-content-end">
        //                                 <button className="ant-btn ant-btn-primary float-end mb-3" onClick={removePushToNchcr} disabled={!pushDisabled}>
        //                                     Remove {selectedLength} Candidates Flagged for NCHCR
        //                                 </button>
        //                             </div>

        //                             <Table
        //                                 rowKey={(record) => record.candidates_ID}
        //                                 dataSource={candidateLists.filterUserCandidates}
        //                                 rowSelection={uInfo?.nchcrMember ? rowSelection : null}
        //                                 columns={columns}
        //                                 size='small'
        //                                 onRow={(record, rowIndex) => {
        //                                     return {
        //                                         onClick: event => { (event.target.id !== 'matchButton' && event.target.id !== 'myCheckbox') ? navigateToCandidateDashboard(record) : console.log("Selected") }, // click row
        //                                         onDoubleClick: event => { }, // double click row
        //                                         onContextMenu: event => { }, // right button click row
        //                                         onMouseEnter: event => { }, // mouse enter row
        //                                         onMouseLeave: event => { }, // mouse leave row
        //                                     };
        //                                 }}
        //                                 expandable={!isDesktop && {
        //                                     expandedRowRender: (record) => dropdownRender(record),
        //                                     rowExpandable: (record) => record.candidates_ID !== 'Not Expandable',
        //                                 }}
        //                                 onChange={handleTableChange}
        //                                 pagination={tableParams.pagination}
        //                             />
        //                         </>
        //                         :
        //                         <Empty description={<p>You currently have no candidates - to begin adding a candidate, click the <i className="far fa-plus-square" /> icon on the top banner, and select 'Add a Candidate'.</p>} />
        //                     }
        //                 </>
        //             }
        //         </>
        //     ),
        // }
    ];

    // if (uPermissions.users_ID && uPermissions.readCandidates !== 'company') {
    //     let companyCandidatesIndex = tabs.findIndex(item => item.key === 'companyCandidates');
    //     tabs.splice(companyCandidatesIndex, 1);
    // }

    // if (!uInfo.nchcrMember) {
    //     let nchcrCandidatesIndex = tabs.findIndex(item => item.key === 'nchcrCandidates');
    //     tabs.splice(nchcrCandidatesIndex, 1);
    // }

    return (
        <>
            {(uPermissions.users_ID && uPermissions.readCandidates === 'none') ?
                <Empty description={<p>You do you have the appropriate access to view candidates. Please contact your system administrator if you believe this is a mistake.</p>} />
                :
                <>
                    <div className="d-flex justify-content-end">
                        <button className="ant-btn ant-btn-primary float-end" onClick={() => setShowAddCandidate(true)}>
                            <i className="far fa-plus-square"></i>
                            &nbsp;
                            Add Candidate
                        </button>
                    </div>

                    <div className="card-container">
                        <Tabs activeKey={activeTab} type="card" items={tabs} onChange={handleTabChange} />
                    </div>

                    <AddCandidate show={showAddCandidate} from='general' hidePane={() => setShowAddCandidate(false)} />
                    <MatchPane show={workflowPane.show} hidePane={() => setWorkflowPane({ show: false, candidate: null })} entity={workflowPane.candidate} from='candidates' placementStatus="Active" />
                </>
            }
        </>
    );
}