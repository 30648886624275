import React, { useEffect, useState, useRef } from 'react';
import { Table, Empty, Tag, Modal, Divider, Select } from 'antd';
import { convertHtmlToText } from '../../helpers/ManipulateHTML';
import JoditEditor from 'jodit-react';
import Moment from 'moment';
import { getEmailHistoryItem } from '../../services/EmailService';
import { toast } from '@rickylandino/react-messages';
import { useRecoilValue } from 'recoil';
import { activeUserInfo } from '../../services/UsersService';
import { fetcher } from '../../swr/fetcher';
import useSWR from 'swr';

export function OldEmailHistory(props) {
    const editor = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const [filterFields, setFilterFields] = useState({
        sentTo: []
    });

    const [toSearchValue, setToSearchValue] = useState('');

    const [filterString, setFilterString] = useState('');

    const [modifiedListData, setModifiedListData] = useState([]);

    const uInfo = useRecoilValue(activeUserInfo);

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
            showSizeChanger: true,
            showQuickJumper: true,
            size: 'default'
        },
    });

    const { data, error, isLoading } = useSWR(`/api/GetEmailHistoryByUser?users_ID=${uInfo.users_ID}&pageSize=${tableParams.pagination.pageSize}&offset=${tableParams.pagination.current - 1}${filterString}${props.searchSpecificEmail ? `&specificEmail=${props.specificEmail}` : ''}`, fetcher);

    useEffect(() => {
        if(props.listData) {
            setModifiedListData([...props.listData.candidates, ...props.listData.recruiters]);
        }
        
    }, [props.listData]);

    useEffect(() => { 
        if (data?.length > 0) {
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: data?.length > 0 ? data[0].totalCount : 0
                }
            });
        }
    }, [data]);

    useEffect(() => {
        let ff = '';

        for (const property in filterFields) {
            if (filterFields[property]) {
                if ((Array.isArray(filterFields[property]) && filterFields[property].length > 0)) {
                    ff += `&filters=${property}:in:${filterFields[property]}`;
                } else if (!Array.isArray(filterFields[property])) {
                    ff += `&filters=${property}:cn:${filterFields[property]}`;
                }
            }
        }

        setFilterString(ff);

    }, [filterFields]);

    function openHistoryModal(record) {
        toast.loading("Fetching email...", { key: 'loading' });

        getEmailHistoryItem(record.emailHistory.emailHistory_ID).then(data => { 
            setSelectedItem(data);
            setIsOpen(true);
            toast.destroy('loading');
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
    };

    function handleFilter(value, property) {
        let ff = { ...filterFields };
        ff[property] = value || null;

        setFilterFields(ff);
    }

    function addCustomEmail(e) {
        let { value } = e.target;

        if (e.keyCode === 13) {
            if (value && !modifiedListData.find(item => item.labelvalue.toLowerCase().includes(value.toLowerCase()))) {
                
                setFilterFields({
                    ...filterFields,
                    sentTo: [...filterFields.sentTo, value]
                });
                setToSearchValue('');
                // if (type === 'to') {
                //     setToList([...toList, {
                //         labelvalue: value,
                //         value
                //     }]);
                //     setToSearchValue('');
                // } else if (type === 'cc') {
                //     setCcList([...ccList, {
                //         labelvalue: value,
                //         value
                //     }]);
                //     setCcSearchValue('');
                // } else if (type === 'bcc') {
                //     setBccList([...bccList, {
                //         labelvalue: value,
                //         value
                //     }]);
                //     setBccSearchValue('');
                // }
            } else {
                setToSearchValue('');
            }
        }

    }

    const columns = [
        {
            title: 'Subject',
            dataIndex: ['emailHistory' ,'subject'],
            key: 'subject',
        },
        {
            title: 'Email Preview',
            dataIndex: 'truncatedContentString',
            key: 'content',
            render: cell => convertHtmlToText(cell),
            ellipsis: true
        },
        {
            title: 'Sent To',
            dataIndex: 'nameList',
            key: 'sentTo',
            ellipsis: true,
            render: (cell) => {
                const emails = cell?.split(';');

                return (
                    <>
                        {emails?.map((e) => {
                            if (e) {
                                return (
                                    <Tag key={e}>
                                        {e}
                                    </Tag>
                                );
                            }
                        })}
                    </>
                )
            }
        },
        {
            title: 'Sent CC',
            dataIndex: ['emailHistory', 'sentCC'],
            key: 'sentCc',
            ellipsis: true,
            render: (cell) => {
                const emails = cell?.split(';');

                return (
                    <>
                        {emails?.map((e) => {
                            if (e) {
                                return (
                                    <Tag key={e}>
                                        {e}
                                    </Tag>
                                );
                            }
                        })}
                    </>
                )
            }
        },
        {
            title: 'Sent BCC',
            dataIndex: ['emailHistory', 'sentBCC'],
            key: 'sentBcc',
            ellipsis: true,
            render: (cell) => {
                const emails = cell?.split(';');

                return (
                    <>
                        {emails?.map((e) => {
                            if (e) {
                                return (
                                    <Tag key={e}>
                                        {e}
                                    </Tag>
                                );
                            }
                        })}
                    </>
                )
            }
        },
        {
            title: 'Date Sent',
            dataIndex: ['emailHistory', 'dateSent'],
            key: 'dateSent',
            render: (cell) => Moment(cell).format("L LT")
        }
    ];

    return (
        <>
        {!props.specificEmail &&
            <>
                <Divider orientation="left">Filter By</Divider>

                <div className="row">
                    <div className="col col-lg-6 col-12">
                        <label>Sent To</label>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            value={filterFields.sentTo}
                            searchValue={toSearchValue}
                            onSearch={(value) => setToSearchValue(value)}
                            placeholder="Please select"
                            onChange={(value) => handleFilter(value, 'sentTo')}
                            options={modifiedListData}
                            optionFilterProp='labelvalue'
                            onInputKeyDown={(e) => addCustomEmail(e)}
                            notFoundContent='Press enter to add an email not found in the list'
                        />
                    </div>
                </div>
                
                <Divider></Divider>
            </>
            }   
            {data?.length > 0 ?
                <div>
                    <Table
                        rowKey={(record) => record.emailHistory.emailHistory_ID}
                        dataSource={data}
                        columns={columns}
                        size='small'
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => openHistoryModal(record), // click row
                                onDoubleClick: event => { }, // double click row
                                onContextMenu: event => { }, // right button click row
                                onMouseEnter: event => { }, // mouse enter row
                                onMouseLeave: event => { }, // mouse leave row
                            };
                        }}
                        onChange={handleTableChange}
                        pagination={tableParams.pagination} 
                    />
                </div>
                :
                <Empty description="No Email History" />
            }

            <Modal
                title="Email History"
                open={isOpen}
                onCancel={() => setIsOpen(false)}
                footer={
                    <div className="d-flex justify-content-between" key="1">
                        <div className="text-center">
                            <button className="ant-btn ant-btn-default m-1" onClick={() => setIsOpen(false)}>Close</button>
                        </div>
                    </div>
                }
                width={1000}
            >
                {selectedItem &&
                    <div className="row">

                        <div className="form-group col-12">
                            <label>Sent To</label>
                            <div>
                                {selectedItem.emailHistory.sentTo?.split(';').map((e) => {
                                    if (e) {
                                        return (
                                            <Tag key={e}>
                                                {e}
                                            </Tag>
                                        );
                                    }
                                })}
                            </div>
                        </div>

                        <div className="form-group col-12">
                            <JoditEditor
                                ref={editor}
                                value={selectedItem.emailHistory.contentString}
                                tabIndex={1} // tabIndex of textarea
                                config={{
                                    height: "500",
                                    allowResizeY: true,
                                    readonly: true,
                                    toolbar: false
                                }}
                            />
                        </div>
                    </div>
                }
            </Modal>
        </>
    );
}