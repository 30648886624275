import { Input } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { activeUserInfo } from '../../services/UsersService';
import Axios from '../../config/axios';

const { Search } = Input;

const CandidateSearchV3 = ({ setAvailableCandidates }) => {
    const [value, setValue] = useState([]);
    const [loading, setLoading] = useState(false);
    const uInfo = useRecoilValue(activeUserInfo);

    var apipath = `/api/GetCandidatesWithPlacementsByUser?org_ID=${uInfo.organizations_ID}&users_ID=${uInfo.users_ID}&from=campaign&sortBy=firstName&sortDirection=asc&placementStatus=Active&searchValue=${''}`

    function handleSearch(value, e) {
        setLoading(true);
        Axios.get(apipath + value).then(res => {
            setLoading(false);
            let candidates = res.data?.userCandidates?.map((uc) => ({
                candidates: {
                    candidates_ID: uc.candidates_ID,
                    firstName: uc.candidateFirstName,
                    lastName: uc.candidateLastName,
                    email1: uc.candidateEmail,
                },
                smsNumber: uc.candidateNumber,
                parentSpecialty: uc.parentSpecialty,
                subSpecialty: uc.subSpecialty,
                toRemove: false
            }));

            setAvailableCandidates(candidates);
        });
    }

    return (
        <>
            <Search className="py-4" placeholder="Search for Keywords..." loading={loading} enterButton onSearch={handleSearch} />
        </>
    );
};
export default CandidateSearchV3;