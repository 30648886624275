import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { NotificationOutlined } from '@ant-design/icons';
import { ManageProfile } from './Manage/ManageProfile';
import { ManageSecuritySettings } from './Manage/ManageSecuritySettings';
import { ManageOrganization } from './Manage/ManageOrganization';
import { ManageUsers } from './Manage/ManageUsers';
import { ManageTags } from './Manage/ManageTags';
import { ManageJobTypes } from './Manage/ManageJobTypes';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { userInfo, userPermissions } from '../../services/UsersService';
import { useNavigate } from 'react-router-dom';
import { ManageEventTypes } from './Manage/ManageEventTypes';
import { useMediaQuery } from 'react-responsive';
import Globals from '../../config/globals';
import { ManageSpecialties } from './Manage/ManageSpecialties';
import { ManageWorkflow } from './Manage/ManageWorkflow';


const { Content, Sider } = Layout;

export function AccountSettingsHome(props) {
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const [openTab, setOpenTab] = useState('');

    const isDesktop = useMediaQuery({ minWidth: Globals.mobileWidth });

    useEffect(() => {
        setOpenTab('manage-profile');
    }, [user]);

    useEffect(() => {
        var alive = true;

        combineLatest(
            userInfo,
            userPermissions
        ).pipe(takeWhile(() => alive)).subscribe(([data, userPermissions]) => {
            if (data?.users_ID) {
                setUser({
                    user: data,
                    userPermissions
                });
            }
        });

        return () => { alive = false; }
    }, []);

    
    const hideMenuItem = !(user?.user?.userType === 'OrgAdmin' || user?.user?.userType === 'SuperAdmin');
    const hideTags = !(user?.user?.userType === 'OrgAdmin' || user?.user?.userType === 'SuperAdmin') && user?.userPermissions?.manageTags === false;
    const hideJobTypes = !(user?.user?.userType === 'OrgAdmin' || user?.user?.userType === 'SuperAdmin') && user?.userPermissions?.manageJobTypes === false;
    const hideEventTypes = !(user?.user?.userType === 'OrgAdmin' || user?.user?.userType === 'SuperAdmin') && user?.userPermissions?.manageEventTypes === false;
    const hideSpecialties = !(user?.user?.userType === 'OrgAdmin' || user?.user?.userType === 'SuperAdmin') && user?.userPermissions?.manageSpecialties === false;
    const hideWorkflowItems = !(user?.user?.userType === 'OrgAdmin' || user?.user?.userType === 'SuperAdmin') && user?.userPermissions?.manageWorkflowItems === false;

    var menuItems = [
        {
            key: 'profile',
            icon: <NotificationOutlined />,
            label: 'My Profile',
            children: [
                { label: 'Profile', key: 'manage-profile', onClick: (item) => setOpenTab(item.key) },
                { label: 'Security Settings', key: 'manage-security', onClick: (item) => setOpenTab(item.key) }
            ]
        },
        {
            key: 'organization',
            icon: <NotificationOutlined />,
            label: 'My Organization',
            children: [
                { label: 'Org Info', key: 'manage-org-info', onClick: (item) => setOpenTab(item.key), hidden: hideMenuItem },
                { label: 'Users', key: 'manage-users', onClick: (item) => setOpenTab(item.key), hidden: hideMenuItem },
                { label: 'Tags', key: 'manage-tags', onClick: (item) => setOpenTab(item.key), hidden: hideTags },
                { label: 'Job Types', key: 'manage-job-types', onClick: (item) => setOpenTab(item.key), hidden: hideJobTypes },
                { label: 'Event Types', key: 'manage-event-types', onClick: (item) => setOpenTab(item.key), hidden: hideEventTypes },
                { label: 'Specialties', key: 'manage-specialties', onClick: (item) => setOpenTab(item.key), hidden: hideSpecialties },
                { label: 'Workflow Items', key: 'manage-workflow-items', onClick: (item) => setOpenTab(item.key), hidden: hideWorkflowItems }
            ]
        }
    ];

    //menuItems = (isRecruiter || isSuperAdmin) ? menuItems.filter(item => item.key !== 'organization') : menuItems;

    const menu = (<Menu
        mode={isDesktop ? "inline" : "horizontal"}
        defaultSelectedKeys={['manage-profile']}
        defaultOpenKeys={['profile', 'organization']}
        style={{
            height: '100%',
        }}
        items={menuItems}
    />);

    return (
        <div>
            
            <div className="text-end mb-2"><i title="Release Notes" className="fa-regular fa-circle-question icon-md hover" onClick={() => navigate('/release-notes', {replace: true}) }></i></div>
            <Content style={{ border: '1px solid' }}>
                <Layout
                    className="site-layout-background"
                    style={{
                        padding: '24px 0',
                    }}
                >
                    {isDesktop ? 
                        <Sider className="settings-sider" width={200} theme='light'>
                            {menu}
                        </Sider>
                        :
                        menu
                    }
                    

                    {/*<Sider className="settings-sider" width={200} theme='light'>*/}
                    {/*    <Menu*/}
                    {/*        mode="inline"*/}
                    {/*        defaultSelectedKeys={['manage-profile']}*/}
                    {/*        defaultOpenKeys={['profile', 'organization']}*/}
                    {/*        style={{*/}
                    {/*            height: '100%',*/}
                    {/*        }}*/}
                    {/*        items={[*/}
                    {/*            {*/}
                    {/*                key: 'profile',*/}
                    {/*                icon: <NotificationOutlined />,*/}
                    {/*                label: 'My Profile',*/}
                    {/*                children: [*/}
                    {/*                    { label: 'Profile', key: 'manage-profile', onClick: (item) => setOpenTab(item.key) },*/}
                    {/*                    { label: 'Security Settings', key: 'manage-security', onClick: (item) => setOpenTab(item.key) }*/}
                    {/*                ]*/}
                    {/*            },*/}
                    {/*            {*/}
                    {/*                key: 'organization',*/}
                    {/*                icon: <NotificationOutlined />,*/}
                    {/*                label: 'My Organization',*/}
                    {/*                children: [*/}
                    {/*                    { label: 'Org Info', key: 'manage-org-info', onClick: (item) => setOpenTab(item.key), hidden: hideMenuItem },*/}
                    {/*                    { label: 'Users', key: 'manage-users', onClick: (item) => setOpenTab(item.key), hidden: hideMenuItem },*/}
                    {/*                    { label: 'Tags', key: 'manage-tags', onClick: (item) => setOpenTab(item.key), hidden: hideTags },*/}
                    {/*                    { label: 'Job Types', key: 'manage-job-types', onClick: (item) => setOpenTab(item.key), hidden: hideJobTypes },*/}
                    {/*                    { label: 'Event Types', key: 'manage-event-types', onClick: (item) => setOpenTab(item.key), hidden: hideEventTypes },*/}
                    {/*                ]*/}
                    {/*            }*/}
                    {/*        ]}*/}
                    {/*    />*/}
                    {/*</Sider>*/}
                    <Content
                        style={{
                            padding: '10px 24px',
                            minHeight: 280,
                        }}
                    >
                        {openTab === 'manage-profile' && <ManageProfile user={user} />}
                        {openTab === 'manage-security' && <ManageSecuritySettings />}
                        {openTab === 'manage-org-info' && <ManageOrganization />}
                        {openTab === 'manage-users' && <ManageUsers />}
                        {openTab === 'manage-tags' && <ManageTags />}
                        {openTab === 'manage-job-types' && <ManageJobTypes />}
                        {openTab === 'manage-event-types' && <ManageEventTypes />}
                        {openTab === 'manage-specialties' && <ManageSpecialties />}
                        {openTab === 'manage-workflow-items' && <ManageWorkflow />}
                    </Content>
                </Layout>
            </Content>
        </div>
    );
}