import React, { useEffect, useState, useRef } from 'react';
import { Table, Empty, Tag, Modal, Divider, Select } from 'antd';
import { convertHtmlToText } from '../../helpers/ManipulateHTML';
import JoditEditor from 'jodit-react';
import Moment from 'moment';
import { getEmailHistoryItem, getEmailReport, getTransactionalEmailHistoryByEmail } from '../../services/EmailService';
import { toast } from '@rickylandino/react-messages';
import { useRecoilValue } from 'recoil';
import { activeUserInfo } from '../../services/UsersService';
import { fetcher } from '../../swr/fetcher';
import useSWR from 'swr';

export function EmailHistory(props) {
    const editor = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const [filterFields, setFilterFields] = useState({
        sentTo: []
    });

    const [toSearchValue, setToSearchValue] = useState('');

    const [filterString, setFilterString] = useState('');

    const [modifiedListData, setModifiedListData] = useState([]);

    const uInfo = useRecoilValue(activeUserInfo);

    const [tableData, setTableData] = useState([]);

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
            showSizeChanger: true,
            showQuickJumper: true,
            size: 'default'
        },
    });

    const { data, error, isLoading } = useSWR(`/api/GetEmailReport?users_ID=${uInfo.users_ID}${filterString}${props.searchSpecificEmail ? `&specificEmail=${props.specificEmail}` : ''}`, fetcher);

    useEffect(() => {
        if(props.listData) {
            setModifiedListData([...props.listData.candidates, ...props.listData.recruiters]);
        }
        
    }, [props.listData]);

    useEffect(() => { 
        setTableData(data?.events || []);
    }, [data]);

    useEffect(() => {
        let ff = '';

        for (const property in filterFields) {
            if (filterFields[property]) {
                if ((Array.isArray(filterFields[property]) && filterFields[property].length > 0)) {
                    ff += `&filters=${property}:in:${filterFields[property]}`;
                } else if (!Array.isArray(filterFields[property])) {
                    ff += `&filters=${property}:cn:${filterFields[property]}`;
                }
            }
        }

        setFilterString(ff);

    }, [filterFields]);

    function openHistoryModal(record) {
        toast.loading("Fetching email...", { key: 'loading' });
        getTransactionalEmailHistoryByEmail(record.messageId).then(data => { 
            setSelectedItem({
                data,
                record
            });
            setIsOpen(true);
            toast.destroy('loading');
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
    };

    function handleFilter(value, property) {
        let ff = { ...filterFields };
        ff[property] = value || null;

        setFilterFields(ff);
    }

    function addCustomEmail(e) {
        let { value } = e.target;

        if (e.keyCode === 13) {
            if (value && !modifiedListData.find(item => item.labelvalue.toLowerCase().includes(value.toLowerCase()))) {
                
                setFilterFields({
                    ...filterFields,
                    sentTo: [...filterFields.sentTo, value]
                });
                setToSearchValue('');
            } else {
                setToSearchValue('');
            }
        }
    }

    const renderEventTag = (event) => { 
        switch(event) {
            case 6:
                return <Tag color="blue">Sent</Tag>;
            case 7:
                return <Tag color="green">Opened</Tag>;
            case 2:
                return <Tag color="red">Hard Bounce</Tag>;
            case 3:
                return <Tag color="orange">Soft Bounce</Tag>;
            case 4:
                return <Tag color="purple">Delivered</Tag>;
            case 5:
                return <Tag color="cyan">Compliant</Tag>;
            default:
                return null;
        }
    }

    const columns = [
        {
            title: 'Event',
            dataIndex: 'event',
            key: 'event',
            render: (cell) => renderEventTag(cell)
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (cell) => Moment(cell).format("L LT")
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
        },
        {
            title: 'To',
            dataIndex: 'email',
            key: 'email',
        }
    ]

    return (
        <>
        {!props.specificEmail &&
            <>
                <Divider orientation="left">Filter By</Divider>

                <div className="row">
                    <div className="col col-lg-6 col-12">
                        <label>Sent To</label>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            value={filterFields.sentTo}
                            searchValue={toSearchValue}
                            onSearch={(value) => setToSearchValue(value)}
                            placeholder="Please select"
                            onChange={(value) => handleFilter(value, 'sentTo')}
                            options={modifiedListData}
                            optionFilterProp='labelvalue'
                            onInputKeyDown={(e) => addCustomEmail(e)}
                            notFoundContent='Press enter to add an email not found in the list'
                        />
                    </div>
                </div>
                
                <Divider></Divider>
            </>
            }   
            {tableData?.length > 0 ?
                <div>
                    <Table
                        rowKey={(record) => `${record.messageId}-${record.event}-${record.date}`}
                        dataSource={tableData}
                        columns={columns}
                        size='small'
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => openHistoryModal(record), // click row
                                onDoubleClick: event => { }, // double click row
                                onContextMenu: event => { }, // right button click row
                                onMouseEnter: event => { }, // mouse enter row
                                onMouseLeave: event => { }, // mouse leave row
                            };
                        }}
                        onChange={handleTableChange}
                        pagination={tableParams.pagination} 
                    />
                </div>
                :
                <Empty description="No Email History" />
            }

            <Modal
                title="Email History"
                open={isOpen}
                onCancel={() => setIsOpen(false)}
                footer={
                    <div className="d-flex justify-content-between" key="1">
                        <div className="text-center">
                            <button className="ant-btn ant-btn-default m-1" onClick={() => setIsOpen(false)}>Close</button>
                        </div>
                    </div>
                }
                width={1000}
            >
                {selectedItem?.data &&
                    <div className="row">

                        <div className="form-group col-12">
                            <label>Event</label>
                            <div>
                                {renderEventTag(selectedItem?.record?.event)}
                            </div>
                        </div>
                        {selectedItem?.record?.reason &&
                        <div className='form-group col-12'>
                            <label>Reason</label>
                            <div>
                                {selectedItem?.record?.reason}
                            </div>
                        </div>
}
                        <div className="form-group col-12">
                            <label>Sent To</label>
                            <div>
                                <Tag key={selectedItem?.data?.content?.email}>
                                    {selectedItem?.data?.content?.email}
                                </Tag>
                            </div>
                        </div>

                        <div className="form-group col-12">
                            <JoditEditor
                                ref={editor}
                                value={selectedItem?.data?.content?.body}
                                tabIndex={1} // tabIndex of textarea
                                config={{
                                    height: "500",
                                    allowResizeY: true,
                                    readonly: true,
                                    toolbar: false
                                }}
                            />
                        </div>
                    </div>
                }
            </Modal>
        </>
    );
}