import React, { useEffect, useState } from 'react';
import { Messenger } from '../SMS/Messenger';
import { Drawer, Modal, Popover, Radio, Slider, Table } from 'antd';
import CandidateSearchV3 from './CandidateSearchV3';
import { MapCampaign } from './MapCampaign';
import { useForm } from "react-hook-form";
import { MailOutlined, MobileOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';
import { activeUserInfo } from '../../services/UsersService';

export const renderRow = (cell, item, hideLocation = false) => {
    return (
        <div>
            {cell ?
                <strong>{item.candidates.firstName} {item.candidates.lastName}
                    {item?.smsNumber && <MobileOutlined className="icon-sm text-success mx-1" />}
                    {item?.candidates?.email1 && <MailOutlined className="icon-sm text-success mx-1" />}
                </strong>
                :
                <>
                    <Popover placement="right" content={<p>System generated candidate based on a text message received OR imported candidate with no name.</p>}>
                        <i className="fa-regular fa-circle-question candidate-text hover"></i>
                    </Popover>
                    &nbsp;
                    {item?.smsNumber && item.smsNumber}
                </>
            }
            
            {!hideLocation &&
                <div>
                    {(item.candidates.city && item.candidates.state) && item.candidates.city + ", " + item.candidates.state}
                    {(!item.candidates.city && item.candidates.state) && item.candidates.state}
                </div>
            }
        </div>
    );
}

//render specialties if they have a populated value
export const renderSpecialties = (cell, item) => {
    if(item.parentSpecialty && item.subSpecialty) {
        return (
            <div>
                {item.parentSpecialty}, {item.subSpecialty}
            </div>
        );
    } else if (item.parentSpecialty) {
        return (
            <div>
                {item.parentSpecialty}
            </div>
        );
    } else if (item.subSpecialty) {
        return (
            <div>
                {item.subSpecialty}
            </div>
        );
    }
}

export const tableColumns = [
    {
        title: 'Candidate',
        dataIndex: ['candidates', 'lastName'],
        key: 'lastName',
        render: (cell, row) => renderRow(cell, row, true)
    },
    {
        title: 'Specialty',
        dataIndex: 'parentSpecialty',
        key: 'parentSpecialty',
        render: renderSpecialties
    }
]

export function AddCampaignMembers({
    showModal,
    hideModal,
    campaigns_ID,
    selectedCandidates,
    handleUpdateMembers
}) {
    const { register, watch, setValue, getValues } = useForm();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const [campaignId, setCampaignId] = useState(campaigns_ID || null);

    const [radioOption, setRadioOption] = useState('Candidates');

    const [modalInfo, setModalInfo] = useState({
        show: false
    });

    const [filterOptions, setFilterOptions] = useState({
        radius: 10
    });

    const [CONSTANTS, setConstants] = useState({
        entity: 'Candidate',
        entityPlural: 'Candidates',
        groupOrOrg: 'Group',
        groupOrOrgPlural: 'Groups'
    });

    const [availableCandidates, setAvailableCandidates] = useState([]);

    const uInfo = useRecoilValue(activeUserInfo);
    
    const subscription = watch((value, { name, type }) => {
        if (name === 'radius') {
            setFilterOptions({ ...filterOptions, radius: value.radius });
        }
    });

    useEffect(() => {
        if (showModal) {
            setModalInfo({
                show: true
            });
        }
    }, [showModal]);

    function handleClose() {
        setModalInfo({
            show: false
        });

        setTimeout(() => {
            hideModal();
        }, 1000);
    }

    function handleRadioChange({ target: { value } }) {
        setAvailableCandidates([]);
        setRadioOption(value);
    };

    function onSelectChange(selectedRowKeys, selectedRows, info) {
        if(info.type !== 'all') {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
        }
        
    };

    function onSelectAllChange(selected, selectedRows, changeRows) {
        var newSelectedRowKeys = selected ? availableCandidates.map(fc => fc.candidates.candidates_ID) : [];
        var newSelectedRows = selected ? [...availableCandidates] : [];
        // if (selected) {
        //     //update selected candidates with all available candidates with no duplicates
        //     var selectedCandidates = [...selectedCandidates];
        //     availableCandidates.forEach(cr => {
        //         let idx = selectedCandidates.findIndex(sc => sc.candidates.candidates_ID === cr.candidates.candidates_ID);
        //         if (idx === -1) {
        //             selectedCandidates.push(cr);
        //         }
        //     });
        // } else {
        //     //remove all available candidates from selected candidates
        //     var selectedCandidates = selectedCandidates.filter(sc => !newSelectedRowKeys.includes(sc.candidates.candidates_ID));

        // }

        // // setMembers({
        // //     ...members,
        // //     selectedCandidates
        // // });

        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedRows(newSelectedRows);
    }

    function handleAvailableCandidates(ac) {
        setAvailableCandidates(ac);

        let selectedKeys = selectedCandidates?.map(sc => sc.candidates.candidates_ID);

        setSelectedRowKeys(selectedKeys);
    }

    function handleSubmit() {
        handleUpdateMembers(selectedRows);
        handleClose();
    }

    function updateCandidatesFromMap(candidateList) {
        let ac = [];

        if (candidateList) {
            ac = candidateList?.map(val => ({
                ...val,
                isSelected: selectedCandidates.find(arr1Obj => arr1Obj.candidates.candidates_ID === val.candidates.candidates_ID) ? true : false
            }));
        }

        setAvailableCandidates(ac);
    }

    function showSearchFields() {
        Modal.info({
            title: 'Keyword Search',
            content: (
                <div>
                    <p>You can search on the following candidate fields</p>
                    <ul>
                        <li>Name</li>
                        <li>In House ID</li>
                        <li>Specialty/Sub-Specialty</li>
                        <li>Phone Number</li>
                        <li>State</li>
                        <li>Recruiter</li>
                        <li>Tags</li>
                    </ul>
                </div>
            ),
            onOk() { },
        });
    }
    

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        onSelectAll: onSelectAllChange,
        getCheckboxProps: (record) => ({
            disabled: selectedCandidates.find(sc => sc.candidates.candidates_ID === record.candidates.candidates_ID),
          })
    };

    const columns = [
        {
            title: 'Search Results',
            dataIndex: ['candidates', 'firstName'],
            render: renderRow
        }];

        const options = [
            {
                label: <span>Keyword Search<i title="See what fields you can search on" className="fa-regular fa-circle-question hover ms-1" onClick={showSearchFields}></i></span>,
                value: CONSTANTS.entityPlural,
            },
            {
                label: 'Location Search',
                value: 'Location'
            }
        ];
    
        if (uInfo.userType === 'SuperAdmin') {
            let idx = options.findIndex(o => o.label === 'Location');
            options.splice(idx, 1);
        }
    

    return (
        <Drawer
            closeIcon={<i className="fas fa-chevron-left float-left"></i>}
            title='Manage Campaign Members'
            placement={'right'}
            onClose={handleClose}
            open={modalInfo.show}
            key='drawer'
            width={'80%'}
            className="d-flex"
            footer={
                <div className="d-flex justify-content-between" key="1">
                    <div>
                        <button className="btn ant-btn-default float-right">Close</button>
                    </div>
                </div>
            }
        >
            <div className="col-12">
                    <div>
                        {uInfo.userType !== 'SuperAdmin' &&
                            <div className="row">

                                <div className="col-12">
                                    <h3>Add Candidates By</h3>
                                    <Radio.Group options={options} onChange={handleRadioChange} value={radioOption} />
                                </div>
                            </div>

                        }
                        {radioOption === 'Candidates' &&
                            <div className="col-12">
                                <CandidateSearchV3 setAvailableCandidates={handleAvailableCandidates} />
                                <Table
                                    size="small"
                                    rowSelection={rowSelection}
                                    columns={tableColumns}
                                    dataSource={availableCandidates}
                                    rowKey={record => record.candidates.candidates_ID}
                                />
                            </div>
                        }

                        {radioOption === 'Location' &&
                            <>
                                <div className="pt-4">
                                    <div className="row">
                                        <div className="col-lg-3 col-12">
                                            <div className="form-label">Filter By</div>
                                            <div className="horizontal-campaigns-divider" />
                                            <div className="form-group">
                                                <div className="form-label">Radius (Miles)</div>
                                                <div className="d-flex">
                                                    <Slider min={5} max={100} step={5} className="w-75" onChange={(value) => setValue('radius', value)} value={filterOptions.radius} />
                                                    <input type="number" min={5} max={100} className="ant-input w-25" {...register('radius')} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <small>Begin your location search by selecting the magnifying glass on the corner of the map.</small>
                                    <div className="horizontal-campaigns-divider" />

                                    <div className="row">
                                        <div className="col col-lg-4 col-12">
                                            <Table
                                                size="small"
                                                rowSelection={rowSelection}
                                                columns={columns}
                                                dataSource={availableCandidates}
                                                rowKey={record => record.candidates.candidates_ID}
                                            />
                                            {/* <List
                                                size="small"
                                                header={<div>Search Results <span className="float-right">{availableCandidates.length} Candidates</span></div>}
                                                style={{ height: '50vh', overflowY: 'auto' }}
                                                bordered
                                                rowKey="candidates.candidates_ID"
                                                dataSource={availableCandidates}
                                                // renderItem={item => <List.Item>{item.candidates.firstName}</List.Item>}
                                                renderItem={item => (
                                                    <List.Item
                                                        actions={item.isSelected ? [<em key="1" className="text-success">Selected</em>] : [<a onClick={() => {
                                                            setMembers({
                                                                ...members,
                                                                selectedCandidates: [
                                                                    ...members.selectedCandidates,
                                                                    item
                                                                ]
                                                            })
                                                        }} key="list-loadmore-edit" className="a hover">Select</a>]}
                                                    >
                                                        <List.Item.Meta
                                                            title={<span>{item.candidates.firstName} {item.candidates.lastName}
                                                                {item?.smsNumber && <MobileOutlined className="icon-sm text-success mx-1" />}
                                                                {item?.candidates?.email1 && <MailOutlined className="icon-sm text-success mx-1" />}
                                                            </span>}
                                                            description={(item.candidates.city && item.candidates.state) && item.candidates.city + ", " + item.candidates.state}
                                                        />
                                                    </List.Item>
                                                )}
                                            /> */}
                                        </div>
                                        <div className="col col-lg-8 col-12">
                                            <MapCampaign updateCandidates={handleAvailableCandidates} filterOptions={filterOptions} />
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        
                        <div className="d-flex align-items-center mt-3 justify-content-start">
                            <button className="ant-btn ant-btn-primary" onClick={handleSubmit}>Add Candidates</button>
                        </div>
                        
                    </div>
                </div>
        </Drawer>
    )
}