import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Alert } from 'antd';
import { confirm, toast } from '@rickylandino/react-messages';
import * as OrganizationCards from './OrganizationCards/index';
import { ManageUsers } from '../AccountSettings/Manage/ManageUsers';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { userInfo } from '../../services/UsersService';
import { getOrgByID, updateOrganization } from '../../services/OrganizationsService';
import { useRecoilState } from 'recoil';
import { activeUserCandidatesState } from '../../services/CandidateService';

export function OrganizationDashboard(props) {

    const { org_ID } = useParams();

    const location = useLocation();
    const navigate = useNavigate();

    const [selectedOrganization, setSelectedOrganization] = useState(location?.state?.selectedOrganization);
    const [activeUserCandidates, setActiveUserCandidates] = useRecoilState(activeUserCandidatesState);
    const [uInfo, setUInfo] = useState({});

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (uInfo?.users_ID) {
            if (uInfo.userType !== 'SuperAdmin') {
                navigate('/dashboard', { replace: true });
            }
        }
    }, [uInfo]);

    useEffect(() => {
        if (org_ID && uInfo?.users_ID) {
            getOrgByID(org_ID).then(data => {
                setSelectedOrganization(data);
                setLoaded(true);
            });
        }
    }, [org_ID, uInfo]);

    useEffect(() => {
        var alive = true;

        combineLatest(
            userInfo
        ).pipe(takeWhile(() => alive)).subscribe(([userInfo]) => {
            if (userInfo?.users_ID) {
                if (!uInfo.users_ID) { //prevent reload of user info
                    setUInfo(userInfo);
                }
            }
        });

        return () => { alive = false; }
    }, []);

    // useEffect(() => {
    //     if (selectedOrganization?.organizations_ID) {
    //         setLoaded(true);
    //     }
    // }, [selectedOrganization]);

    // useEffect(() => {
    //     setLoaded(false);

    //     setSelectedOrganization({ ...location?.state?.selectedOrganization });

    // }, [location]);

    var goBackText = "Return to my organizations";


    function goBack() {
        
        navigate("/organizations", { replace: true });
        
    }

    function handleArchiveOrganization() {
        confirm({
            title: <span><i className='fas fa-archive text-warning icon-md pr-1'></i> &nbsp; You are about to suspend this organization.</span>,
            content: "Are you sure you would like to proceed?",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        },
            (ButtonPressed) => {
                if (ButtonPressed === "Yes") {
                    let postdata = { ...selectedOrganization };
                    postdata.isActive = false;
                    updateOrganization(postdata).then(data => {
                        if (data) {
                            toast.success("Organization has been suspended");
                            navigate("/organizations", { replace: true });
                        } else {
                            toast.error("Something went wrong");
                        }
                    });
                    return 0;
                }
                if (ButtonPressed === "No") {
                    return 0;
                }
            }
        );
    }

    function restoreOrganization() {
        let postdata = { ...selectedOrganization };
        postdata.isActive = true;
        updateOrganization(postdata).then(data => {
            if (data) {
                toast.success("Organization has been restored");
                setSelectedOrganization(postdata);
            } else {
                toast.error("Something went wrong");
            }
        });
    }

    function handleDeleteOrganization() {
        confirm({
            title: <span><i className='far fa-trash-alt text-danger icon-md pr-1'></i> &nbsp; You are about to permanently delete this organization and all associated data.</span>,
            content: "Are you sure you would like to proceed? This action cannot be undone.",
            buttons: ["Yes", "No"],
            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        },
            (ButtonPressed) => {
                if (ButtonPressed === "Yes") {
                    //toast.loading("Deleting candidate...", { key: 'loading' });

                    //let postdata = {
                    //    Id: selectedOrganization.candidates.candidates_ID
                    //}

                    //deleteCandidate(postdata).then(data => {
                    //    if (data) {
                    //        toast.destroy('loading');
                    //        userCandidates.next(data);
                    //        setActiveUserCandidates(data);
                    //        toast.success("Candidate data has been deleted");

                    //        navigate("/candidates", { replace: true });
                    //    }
                    //}).catch(error => {
                    //    toast.error('Something went wrong');
                    //});

                    return 0;
                }
                if (ButtonPressed === "No") {
                    return 0;
                }
            }
        );
    }

    return (
        <div>
            {selectedOrganization?.isActive === false &&
                <Alert message="This organization is currently suspended." type="warning" />
            }
            <div className="horizontal-organizations-divider-thick" />

            <div className="row mt-3">

                <div className="col col-md-4 col-12">
                    <div className="margin-0-10">
                        <h1 className="fa-2x">{selectedOrganization?.name}</h1>

                        <span className="a" onClick={goBack}><i className="fas fa-arrow-left"></i> {goBackText}</span>
                    </div>
                </div>

                <div className="col col-md-8 col-12">
                    {/*<div className="float-end">*/}
                    {/*    <button className="ant-btn ant-btn-danger float-right margin-0-10" onClick={handleDeleteOrganization}><i className='far fa-trash-alt'></i> &nbsp; Delete this Organization</button>*/}
                    {/*</div>*/}

                    <div className="float-end me-3">
                        {selectedOrganization?.isActive ?
                            <button className="ant-btn ant-btn-warning float-right margin-0-10" onClick={handleArchiveOrganization}><i className='fas fa-archive'></i> &nbsp; Suspend this Organization</button>
                            :
                            <button className="ant-btn ant-btn-restore float-right margin-0-10" onClick={restoreOrganization}><i className='fas fa-archive'></i> &nbsp; Restore this Organization</button>
                        }
                    </div>
                </div>

            </div>
            {loaded &&
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <OrganizationCards.ContactInformationCard {...props} selectedOrganization={selectedOrganization} updateOrganizationInformation={(selectedOrg) => setSelectedOrganization(selectedOrg)} />
                    </div>

                    <div className="col-12">
                        <ManageUsers organizations_ID={selectedOrganization?.organizations_ID} from='orgUsers' isAdminOrg={selectedOrganization?.name === 'RecruiterBalm'} />
                    </div>
                </div>
            }
        </div>
    );
}