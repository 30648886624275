import React, { useState, useEffect } from 'react';

import { Document, Page, pdfjs } from "react-pdf";
import { Alert, Empty, Pagination, Spin } from 'antd';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export function ResumeCard(props) {
    
    const [ready, setReady] = useState(false);
    
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);

    useEffect(() => {
        if(typeof props.resume === 'string') {
            setReady(true);
        }

        if(!props.resume) {
            setNumPages(0);
            setPageNumber(1);
        }
    }, [props.resume]);


    function onDocumentLoadSuccess({ numPages }) {
        props.setResumeImported(true);
        setNumPages(numPages);
        setPageNumber(1);
    }

    function onChangePage(page) {
        setPageNumber(page);
    };

    return (
        <>
            {props.resume && !ready && 
                <Spin tip="Loading...">
                    <Alert
                        message="Loading Resume"
                        type="info"
                    />
                </Spin>
            }
            {typeof props.resume === 'string' && ready &&       
                <>
                <iframe src={props.resume} width='100%' style={{height: 'calc(100% - 65px)'}} />     
                {/* <Document file={props.resume || null} onLoadSuccess={onDocumentLoadSuccess} noData={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={props.from === "parse" ? "No resume provided" : "This candidate has no resume. Add a resume in the attachments section."} />}>
                    <Page pageNumber={pageNumber} />
                </Document> */}
                </>
            }
            {/* {numPages > 1 &&
                <Pagination className="text-center" simple defaultCurrent={1} pageSize={1} total={numPages} onChange={onChangePage} />
            } */}
        </>    
    );
}